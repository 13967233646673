/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@material-ui/core";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Tooltip } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { CheckSquare, MessageSquare, Square } from "react-feather";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import * as Yup from "yup";
import { StaticButton } from "../../../../components/Button";
import FormikControl from "../../../../components/FormikControl";
import { CreditCardListItem } from "../../../../components/ListItens";
import LoaderSpinner from "../../../../components/LoaderSpinner";
import {
  UserAvatar,
  handleUserNames,
} from "../../../../components/UserElements";
import useAuth from "../../../../hooks/auth";
import useEvent from "../../../../hooks/event";
import api from "../../../../services/api";
import { PtBr } from "../../../../services/pt_br";
import { BigFont, EmptyContainer } from "../../../../styles/globalStyles";
import convertCurrencyPtBr from "../../../../utils/convertCurrencyPtBr";
import getProfessionName from "../../../../utils/getProfessionName";
import handleErrors from "../../../../utils/getValuesErrors";
import { MessageButton } from "../../../Client/Professionals/styles";
import { EmptyText, EmptyTitle } from "../../../Contents/EmptyContainer/styles";
import { ListCardPayment } from "../../../Professional/ProfessionalUpdate/Subscribe";
import { ProfileBody } from "../../../Public/Profile/components";
import {
  EventInfoContainer,
  EventWrapper,
  LightSmallText,
  ProfileBanner,
  ProfileName,
  ProfileWrapper,
  SmallText,
  WrapperContainer,
} from "../../../Public/Profile/components/styles";
import { EmptyImage } from "../../Mensagens/styles";
import {
  PaymentContainer,
  PaymentMethod,
  SMFontGray,
  WrapperTitle,
} from "../Payment/styles";
import {
  AddressInfo,
  BFont,
  RowItem,
  RowLine,
  SMFRegular,
  SMFontL,
  ScheduleBody,
  StyledButton,
  TypeContainer,
} from "../Schedules/styles";
import {
  BoxWrapperSchedule,
  ButtonContainer,
  CloseIcon,
  IconArea,
  ScheduleButton,
  WarningIcon,
} from "../styles";
import {
  ButtonAddCreditCard,
  ButtonCoupon,
  CancelButton,
  CardButtonMethod,
  ContainerCardPayment,
  FlexStartContainer,
  FlexStartText,
  FlexWrapper,
  HeaderWrapperResum,
  Hr,
  Main,
  MainCreateEvent,
  MedalIcon,
  PaymentButton,
  PaymentCreditCard,
  PaymentWrapper,
  PixQrCode,
  RowButtons,
  SMFontBlack,
  WrapperPayment,
  WrapperPaymentPix,
  WrapperTitleResum,
} from "./styles";

function ScheduleRecurrence({ ...props }) {
  const { values, handleChangeFrequency, handleChangeDuration } = props;

  return (
    <TypeContainer style={{ marginBottom: "1rem" }}>
      <FormControl style={{ width: "250px" }}>
        <InputLabel>{<SMFontL>Frequência</SMFontL>}</InputLabel>
        <Select
          value={values?.frequency}
          label="Frequência"
          onChange={handleChangeFrequency}
        >
          <MenuItem value={"weekly"}>
            <SMFRegular>Semanal</SMFRegular>
          </MenuItem>
          <MenuItem value={"biweekly"}>
            <SMFRegular>Quinzenal</SMFRegular>
          </MenuItem>
          <MenuItem value={"monthly"}>
            <SMFRegular>Mensal</SMFRegular>
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl style={{ width: "250px", marginTop: "10px" }}>
        <InputLabel>{<SMFontL>Duração da recorrência</SMFontL>}</InputLabel>
        <Select
          value={values?.duration}
          label="Duração da recorrência"
          onChange={handleChangeDuration}
        >
          <MenuItem value={"one_month"}>
            <SMFRegular>1 mês</SMFRegular>
          </MenuItem>
          <MenuItem value={"two_month"}>
            <SMFRegular>2 meses</SMFRegular>
          </MenuItem>
          <MenuItem value={"three_month"}>
            <SMFRegular>3 meses</SMFRegular>
          </MenuItem>
        </Select>
      </FormControl>

      <SMFontL style={{ padding: "0 2rem 0.5rem 2rem", marginTop: "2rem" }}>
        ¹ Agendamentos recorrentes ocorrem sempre no mesmo dia da semana e na
        mesma hora marcados.
      </SMFontL>
      <SMFontL style={{ padding: "0.5rem 2rem 0 2rem" }}>
        ² Agendamentos recorrentes precisam ser renovados após a duração
        indicada.
      </SMFontL>
    </TypeContainer>
  );
}

function ScheduleMonthlySubscription({ ...props }) {}

function SchedulePackage({ ...props }) {
  const { appointmentPackages } = props;

  return (
    <TypeContainer>
      <FormControl style={{ width: "250px" }}>
        <InputLabel>{<SMFontL>Quantidade de consultas</SMFontL>}</InputLabel>
        <Select value={6} label="Quantidade de consultas">
          {appointmentPackages?.map(item => (
            <MenuItem key={item.id} value={item.id}>
              <SMFRegular>{item.name}</SMFRegular>
              <SMFRegular style={{ margin: "0 0.3rem 0 0.3rem" }}>
                {item.number_of_appointments}
              </SMFRegular>
              <SMFRegular>{convertCurrencyPtBr(item.price)}</SMFRegular>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </TypeContainer>
  );
}

export function Schedule({ ...props }) {
  const { professional, values, setFieldValue } = props;
  const [paymentMethod, setPaymentMethod] = useState("prePaid");

  const options = [
    {
      label: "Agendamento Individual (Padrão)",
      value: "individual_scheduling",
    },
    {
      label: "Agendamento recorrente",
      value: "recurrent_scheduling",
    },
    // {
    //   label: "Pacote de consultas",
    //   value: "consulting_package",
    // },
    // {
    //   label: "Assinatura mensal",
    //   value: "monthly_subscription",
    // },
  ];

  useEffect(() => {
    if (paymentMethod === "postPaid") {
      handleChangePostPaid();
    } else if (paymentMethod === "externalPayment") {
      handleChangeExternalPayment();
    } else {
      setFieldValue("postPaid", false);
      setFieldValue("externalPayment", false);
      setFieldValue("paymentMethod", "credit_card");
    }
  }, [paymentMethod]);

  const handleChangePaymentMethod = event => {
    setPaymentMethod(event.target.value);
  };

  const handleChangeRecurrent = ({ target }) => {
    setFieldValue("recurrent", false);
    setFieldValue("individualScheduling", false);
    setFieldValue("monthlySubscription", false);
    setFieldValue("consultingPackage", false);
    if (target.value === "recurrent_scheduling")
      return setFieldValue("recurrent", true);
    if (target.value === "individual_scheduling")
      return setFieldValue("individualScheduling", true);
    if (target.value === "monthly_subscription")
      return setFieldValue("monthlySubscription", true);
    if (target.value === "consulting_package")
      return setFieldValue("consultingPackage", true);
  };

  const handleChangeFrequency = event => {
    setFieldValue("frequency", event.target.value);
  };

  const handleChangeDuration = event => {
    setFieldValue("duration", event.target.value);
  };

  const handleChangePostPaid = () => {
    setFieldValue("postPaid", true);
    setFieldValue("postPaidDay", professional?.clinic?.postPaidDay);
    setFieldValue("paymentMethod", "credit_card");
    setFieldValue("externalPayment", false);
  };

  const handleChangeExternalPayment = () => {
    setFieldValue("externalPayment", true);
    setFieldValue("paymentMethod", "external");
    setFieldValue("postPaid", false);
  };

  return (
    <ScheduleBody style={{ alignItems: "flex-start", flexDirection: "column" }}>
      <TypeContainer>
        <BFont>Agendar Atendimento</BFont>
        <SMFontL>Modalidade</SMFontL>
        <RowItem style={{ justifyContent: "center" }}>
          <StyledButton
            style={{ cursor: "default" }}
            selected={values?.appointmentType}
          >
            {values?.appointmentType === "online" ? "Online" : "Presencial"}
          </StyledButton>
        </RowItem>
      </TypeContainer>

      <Hr />

      {professional?.availabilityRule?.appointmentTypes === "in_person" && (
        <AddressInfo>
          <SMFRegular style={{ fontSize: "1.3rem", fontWeight: "700" }}>
            Endereço:
          </SMFRegular>
          <SMFRegular>{professional?.address?.neighborhood}</SMFRegular>
          <SMFRegular>{professional?.address?.street}</SMFRegular>
          <SMFRegular>
            {professional?.address?.city} CEP:{professional?.address?.cep}
          </SMFRegular>
        </AddressInfo>
      )}

      <TypeContainer>
        <RowItem style={{ paddingTop: "inherit" }}>
          <FormikControl
            control="selectMultLang"
            name="typeSchedule"
            label="Tipo de agendamento"
            onChange={handleChangeRecurrent}
            options={options}
            upLabel
            defaultValue="individual_scheduling"
          />
        </RowItem>

        {values?.recurrent && (
          <Fragment>
            <RowItem>
              <BFont>Agendamento recorrente</BFont>
            </RowItem>
            <ScheduleRecurrence
              values={values}
              handleChangeFrequency={handleChangeFrequency}
              handleChangeDuration={handleChangeDuration}
            />
          </Fragment>
        )}

        {values?.monthlySubscription && (
          <ScheduleMonthlySubscription values={values} />
        )}

        {values?.consultingPackage && (
          <SchedulePackage
            appointmentPackages={professional?.clinic?.appointmentPackages}
          />
        )}
      </TypeContainer>

      {professional.clinic.id && (
        <Fragment>
          <Hr />
          <TypeContainer>
            <RowItem>
              <BFont>Clínica - {professional.clinic.name}</BFont>
            </RowItem>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                padding: "0 10px 20px 0",
              }}
            >
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={paymentMethod}
                  onChange={handleChangePaymentMethod}
                  style={{ fontFamily: "Open Sans" }}
                >
                  {professional?.clinic.prePaid && (
                    <FormControlLabel
                      value="prePaid"
                      control={<Radio />}
                      label={
                        <Typography>
                          Realizar pagamento PRÉ-PAGO (Padrão)
                        </Typography>
                      }
                      defaultChecked
                    />
                  )}
                  {professional?.clinic.postPaid && (
                    <FormControlLabel
                      label={
                        <Typography>Realizar agendamento PÓS-PAGO</Typography>
                      }
                      value="postPaid"
                      control={<Radio />}
                    />
                  )}
                  {professional?.clinic.externalPayment && (
                    <FormControlLabel
                      value="externalPayment"
                      control={<Radio />}
                      label={
                        <Typography>
                          Realizar pagamento DIRETO NA CLÍNICA
                        </Typography>
                      }
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </div>
          </TypeContainer>
        </Fragment>
      )}
    </ScheduleBody>
  );
}

export function PayPix({ data }) {
  const [values, setValues] = useState({});
  const [icon, setIcon] = useState(<ContentCopyRoundedIcon />);
  const [copyLabel, setCopyLabel] = useState("PIX Copia e Cola");
  const theme = useTheme();

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const handleClick = async () => {
    setIcon(<CheckRoundedIcon />);
    setCopyLabel("Copiado");

    await delay(10000);

    setIcon(<ContentCopyRoundedIcon />);
    setCopyLabel("PIX Copia e Cola");
  };

  useEffect(() => {
    setValues(data.attributes);
  }, [data]);

  return (
    <WrapperPaymentPix>
      <WrapperPayment style={{ width: "80%" }}>
        <SMFontGray
          style={{
            alignSelf: "center",
            marginBottom: "10px",
            color: theme.pink,
            textAlign: "justify",
            fontSize: "0.8rem",
          }}
        >
          <ErrorOutlineIcon
            style={{
              color: theme.pink,
              marginRight: "5px",
              marginBottom: "-5px",
            }}
          />
          {
            'Para efetuar o pagamento via PIX, escaneie o QR Code abaixo com a câmera do seu celular ou clique na opção "PIX Copia e Cola"'
          }
        </SMFontGray>
        <PixQrCode size={300} value={values?.payment?.pixQrCode} />
        <WrapperTitle
          style={{
            alignSelf: "center",
            marginTop: "5px",
            fontSize: "0.8rem",
            fontWeight: "300",
            width: "100%",
          }}
        >
          Escaneie o QrCode acima para realizar o pagamento
        </WrapperTitle>
      </WrapperPayment>
      <Button
        variant="outlined"
        sx={{
          backgroundColor: theme.primary_blue,
          borderRadius: "2rem",
          color: theme.white,
          width: "80%",
          "&:hover": {
            backgroundColor: theme.white,
            color: theme.primary_blue,
          },
        }}
        endIcon={icon}
        onClick={() => {
          handleClick();
          navigator.clipboard.writeText(values.payment.pixQrCode);
        }}
      >
        {copyLabel}
      </Button>
    </WrapperPaymentPix>
  );
}

export function CreateEvent({ ...props }) {
  const history = useHistory();
  const theme = useTheme();
  const { form, next, professional, user } = props;
  const { errors, values, setValues, setFieldValue } = form;
  const [isProfessional] = useState(user?.id === professional.id);
  const FieldValues = [
    "professionalId",
    "duration",
    "startDate",
    "startTime",
    "appointmentType",
  ];

  function handleSubmit() {
    if (!handleErrors(errors, FieldValues)) {
      next();
    }
  }

  return (
    <MainCreateEvent>
      <ProfileBanner client={"true"}>
        <ProfileWrapper>
          <UserAvatar src={professional.avatar} />
          <WrapperContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <ProfileName style={{ fontSize: "1.5rem" }}>
                {handleUserNames(professional)}
              </ProfileName>
              {!professional.clinic.id && professional.acceptPromotion && (
                <MedalIcon />
              )}
            </div>
            <LightSmallText>
              {getProfessionName(professional?.profession, "toPt") ||
                "Indisponível"}{" "}
              | {professional?.address?.city}
            </LightSmallText>
            <LightSmallText style={{ marginTop: "1rem" }}>
              Próxima data:{" "}
              <p
                style={{
                  fontWeight: "700",
                  opacity: "1",
                  color: "#fff",
                  marginLeft: "0.325rem",
                }}
              >
                {moment(
                  professional?.availabilityRule?.nextAvailableTime,
                ).format("MMM, DD/MM/YYYY")}
              </p>
            </LightSmallText>
          </WrapperContainer>
        </ProfileWrapper>
        <EventWrapper>
          <EventInfoContainer>
            <SmallText style={{ width: "100%" }}>
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "700",
                  opacity: "1",
                  color: "#fff",
                  marginRight: "0.325rem",
                }}
              >
                {convertCurrencyPtBr(professional?.sessionPrice)}
              </p>
              {" por " +
                professional?.availabilityRule?.appointmentDuration +
                "min"}
            </SmallText>
            <LightSmallText style={{ marginTop: "1rem", width: "100%" }}>
              {"CRP: "}
              {professional?.professionalDocumentNumber || "Indisponível"}
            </LightSmallText>
          </EventInfoContainer>
          <EventInfoContainer>
            {professional?.availabilityRule?.appointmentTypes?.map(
              availability => (
                <LightSmallText
                  key={availability}
                  style={{ marginBottom: "1rem" }}
                >
                  {["in_person", "online"].includes(availability) ? (
                    <CheckSquare />
                  ) : (
                    <Square />
                  )}
                  <p style={{ marginLeft: "0.5rem" }}>
                    {availability === "in_person" ? "Presencial" : "Online"}
                  </p>
                </LightSmallText>
              ),
            )}
          </EventInfoContainer>
          <EventInfoContainer className="--section-icon">
            {isProfessional ? (
              <StaticButton
                width="200px"
                buttonColor={theme.secondary}
                title={PtBr.edit_professional_profile}
              />
            ) : (
              user && (
                <Tooltip title="Enviar mensagem">
                  <MessageButton
                    onClick={() => history.push(`/client/messages`)}
                  >
                    <MessageSquare className="-icon" />
                  </MessageButton>
                </Tooltip>
              )
            )}
            <LightSmallText
              style={{ marginTop: "1rem", fontWeight: "700", fontSize: "1rem" }}
            >
              {`${
                professional.reviews?.length !== 0
                  ? professional.reviews?.length
                  : "0"
              } avaliações`}
            </LightSmallText>
          </EventInfoContainer>
        </EventWrapper>
      </ProfileBanner>
      <ProfileBody
        user={user}
        professional={professional}
        publicP={user.id ? false : true}
        handleSubmit={handleSubmit}
        values={values}
        setValues={setValues}
        setFieldValue={setFieldValue}
      />
    </MainCreateEvent>
  );
}

export function PayEvent({ ...props }) {
  const PERCENTAGE_DEFAULT = 10; // porcentagem padrão para escolas sem definição de descontos
  const { form, next, professional, pixData, loading, setLoading } = props;
  const { values, errors, setValues, setFieldValue } = form;
  const { user } = useAuth();
  const { infoCardCredit, scheduleInfo, setScheduleInfo } = useEvent();
  const history = useHistory();
  const [creditCards, setCreditCards] = useState([]);
  const [selected, setSelected] = useState(null);
  const FieldValues = ["paymentMethod", "creditCardId", "couponId"];
  const [coupon, setCoupon] = useState(null);
  const [code, setCode] = useState(scheduleInfo?.code || "");
  const [validateCoupon, setValidateCoupon] = useState(
    scheduleInfo?.code ? true : false,
  );
  const [validated, setValidated] = useState(false);

  const validateCouponId = async () => {
    if (coupon && validated) {
      return coupon.id;
    }
    return null;
  };

  async function handleSubmit() {
    const couponId = await validateCouponId(values);
    if (!handleErrors(errors, FieldValues)) {
      if (values.paymentMethod === "external") {
        next({ ...values, couponId });
      } else if (values.paymentMethod === "credit_card") {
        if (values.paymentMethod && values.creditCardId) {
          next({ ...values, couponId });
        } else {
          setLoading(false);
          toast.error("As informações do cartão estão incompletas!");
        }
      } else if (values.paymentMethod === "pix") {
        next({ ...values, creditCardId: "", couponId });
      }
    }
  }

  const handleChangeCode = event => {
    setCode(event.target.value);
    setValidated(false);
  };

  const handleContinue = () => {
    if (validated) {
      handleSubmit();
    } else if (validated && code !== "") {
      toast.error(
        "Valide o cupom ou deixe o campo em branco antes de prosseguir!",
      );
    } else {
      handleSubmit();
    }
  };

  const handleDeleteCard = async id => {
    try {
      await api.delete(`${user.type}s/credit_cards/${id}`);
      setCreditCards(creditCards?.filter(card => card.id !== id));
      toast.success("Cartão deletado com sucesso.");
    } catch (error) {
      toast.error("Não foi possivel deletar o cartão");
    }
  };

  const handleCouponType = (cp, sessionP) => {
    if (cp?.discountPercentage !== "0.0") {
      return (sessionP * cp.discountPercentage) / 100;
    } else {
      return cp.discountPrice;
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const getCreditCards = async () => {
      try {
        const { data } = await api.get(`${user.type}s/credit_cards`, {
          signal: controller.signal,
        });
        setCreditCards(data.data);
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error("Erro ao buscar histórico de cartões");
      } finally {
        setLoading(false);
      }
    };
    getCreditCards();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    const getValidateCoupon = async () => {
      try {
        const { data } = await api.post("clients/validate-coupon", {
          data: {
            attributes: {
              professional_id: professional.id,
              code: code,
            },
          },
        });
        toast.success("Cupom disponível!");
        setCoupon(data.data);
      } catch (error) {
        setCoupon(null);
        switch (error?.response?.data?.error) {
          case "Coupon already used":
            toast.error("Cupom já utilizado!");
            break;
          case "Coupon ja ultilizado":
            toast.error("Cupom já utilizado!");
            break;
          case "Coupon not found":
            toast.error("Cupom inválido!");
            break;
          case "Professional not available for coupond":
            toast.error("Cupom indisponível para este profissional!");
            break;
          case "Coupon expired":
            toast.error("Cupom expirado!");
            break;
          case "Coupon valido apenas para profissionais do plano gratuito":
            toast.error(
              "Cupom válido apenas para profissionais do plano gratuito",
            );
            break;
          case "Coupon valido apenas para primeira sessao":
            toast.error("Cupom válido apenas para primeira sessão");
            break;
          default:
            toast.error("Erro desconhecido ao tentar validar cupom");
            break;
        }
      } finally {
        setValidated(true);
      }
    };
    if (validateCoupon) {
      getValidateCoupon();
      setValidateCoupon(false);
    }
  }, [validateCoupon]);

  useEffect(() => {
    if (
      coupon?.attributes &&
      professional?.sessionPrice -
        handleCouponType(coupon?.attributes, professional?.sessionPrice) ===
        0
    ) {
      setFieldValue("paymentMethod", "pix");
      setFieldValue("fullAmountCovered", true);
    }
  }, [coupon?.attributes]);

  useEffect(() => {
    const creditCard = creditCards?.find(card => card?.attributes?.default);
    const creditCardId = String(infoCardCredit || creditCard?.id);
    setFieldValue("creditCardId", creditCardId);
  }, [creditCards, infoCardCredit]);

  useEffect(() => {
    if (values.paymentMethod === "external") return;
    if (
      ["credit_card", "all_payment", null].includes(
        professional?.preferentialPayment,
      ) &&
      !pixData
    ) {
      setFieldValue("paymentMethod", "credit_card");
    } else setFieldValue("paymentMethod", "pix");
  }, [pixData, professional]);

  const percentage = user?.school
    ? user.school.discount_percentage
    : PERCENTAGE_DEFAULT;

  const handleSessionPrice = () => {
    if (professional?.acceptPromotion === false)
      return professional?.sessionPrice;
    else if (!user?.school || coupon?.attributes)
      return professional?.sessionPrice;
    const sessionPrice =
      professional?.sessionPrice -
      (professional?.sessionPrice * percentage) / 100;
    return sessionPrice;
  };

  const percentagePrice = (professional?.sessionPrice * percentage) / 100;

  const sessionPrice = handleSessionPrice();

  return (
    <PaymentContainer>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <PaymentWrapper>
          <HeaderWrapperResum>
            <WrapperTitleResum>Resumo do Agendamento</WrapperTitleResum>
            <FlexWrapper>
              <SMFontBlack
                style={{
                  marginBottom: "0.5rem",
                }}
              >
                Profissional:{" "}
                <SMFontBlack style={{ fontSize: "1.15rem" }} bold>
                  {["", null].includes(professional?.socialName)
                    ? professional?.firstName
                    : professional.socialName}
                </SMFontBlack>
              </SMFontBlack>
              <SMFontBlack bold>
                <span style={{ fontWeight: "400" }}>Tipo da sessão: </span>
                {`${
                  ["in_person"].includes(
                    values?.appointmentType ||
                      pixData?.attributes?.appointmentType,
                  )
                    ? "Presencial"
                    : "Online"
                }`}
              </SMFontBlack>
              <SMFontBlack bold>
                <span style={{ fontWeight: "400" }}>Duração: </span>
                {`${values?.duration || pixData?.attributes?.duration} min`}
              </SMFontBlack>
              <SMFontBlack bold>
                <span style={{ fontWeight: "400" }}>Data: </span>
                {`${moment(values.startDate).format("DD [de] MMMM [de] YYYY")}
                às ${moment(
                  values?.startTime || pixData?.attributes?.startTime,
                  "HH:mm:ss",
                ).format("HH:mm")}`}
              </SMFontBlack>
              <FlexStartContainer>
                <FlexStartText style={{ marginRight: "0.325rem" }}>
                  Valor da consulta:
                </FlexStartText>
                <FlexStartText bold>
                  {` ${convertCurrencyPtBr(professional?.sessionPrice)}`}
                </FlexStartText>
              </FlexStartContainer>
              {values.postPaid && (
                <FlexStartContainer style={{ marginBottom: "0.5rem" }}>
                  <FlexStartText>Consulta pós-paga: </FlexStartText>
                  <FlexStartText bold style={{ marginLeft: "0.5rem" }}>
                    {`Pagamento será realizado no dia ${values.postPaidDay}!`}
                  </FlexStartText>
                </FlexStartContainer>
              )}
            </FlexWrapper>
            <TypeContainer>
              <RowLine style={{ alignItems: "center" }}>
                <FormControl
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <FormikControl
                    bgColor="#fff"
                    type="text"
                    control="input"
                    value={code}
                    onChange={handleChangeCode}
                    label="Cupom de desconto"
                    placeholder="Código do Cupom"
                    upLabel
                    downLabel
                  />
                </FormControl>
                <ButtonCoupon
                  onClick={() => {
                    setValidateCoupon(true);
                    setScheduleInfo({
                      ...scheduleInfo,
                      code,
                    });
                  }}
                >
                  Validar cupom
                </ButtonCoupon>
              </RowLine>
            </TypeContainer>
            <Hr />
            <Container style={{ padding: "0px" }}>
              {["in_person"].includes(
                values?.appointmentType || pixData?.attributes?.appointmentType,
              ) && (
                <FlexWrapper style={{ marginBottom: "1rem" }}>
                  <WrapperTitleResum
                    style={{ fontSize: "1rem", paddingBottom: "0.2rem" }}
                  >
                    Endereço:
                  </WrapperTitleResum>
                  <SMFontBlack>
                    {`${professional?.address?.street} -
                    ${professional?.address?.street_number} -
                    ${professional?.address?.neighborhood},
                    ${professional?.address?.city} -
                    ${professional?.address?.state}`}
                  </SMFontBlack>
                </FlexWrapper>
              )}
              <FlexWrapper>
                {coupon && (
                  <FlexStartContainer>
                    <FlexStartText style={{ marginRight: "0.325rem" }}>
                      Valor do desconto:{" "}
                    </FlexStartText>
                    <FlexStartText bold>
                      {`${convertCurrencyPtBr(
                        handleCouponType(
                          coupon?.attributes,
                          professional?.sessionPrice,
                        ),
                      )}`}
                    </FlexStartText>
                  </FlexStartContainer>
                )}
                {user?.school?.discount_percentage > 0 &&
                  professional?.acceptPromotion &&
                  !coupon && (
                    <FlexStartContainer>
                      <FlexStartText style={{ marginRight: "0.325rem" }}>
                        Desconto para conveniado:
                      </FlexStartText>
                      <FlexStartText bold>
                        {convertCurrencyPtBr(percentagePrice)}
                      </FlexStartText>
                    </FlexStartContainer>
                  )}
                <FlexStartContainer>
                  <FlexStartText style={{ marginRight: "0.325rem" }}>
                    Cobrança total:
                  </FlexStartText>
                  <FlexStartText bold>
                    {coupon
                      ? `${convertCurrencyPtBr(
                          professional.sessionPrice -
                            handleCouponType(
                              coupon?.attributes,
                              professional?.sessionPrice,
                            ) <=
                            0
                            ? 0
                            : professional?.sessionPrice -
                                handleCouponType(
                                  coupon?.attributes,
                                  professional?.sessionPrice,
                                ),
                        )}`
                      : `${convertCurrencyPtBr(sessionPrice)}`}
                  </FlexStartText>
                </FlexStartContainer>
              </FlexWrapper>
            </Container>
          </HeaderWrapperResum>
          {!values.externalPayment ? (
            <PaymentCreditCard
              pix={values.paymentMethod === "pix" || pixData ? "true" : "false"}
            >
              <PaymentMethod>
                <WrapperTitle>
                  {coupon?.attributes &&
                  professional?.sessionPrice -
                    handleCouponType(
                      coupon?.attributes,
                      professional?.sessionPrice,
                    ) ===
                    0
                    ? "Agendamento coberto pela promoção"
                    : "Selecione a forma de pagamento"}
                </WrapperTitle>
                {coupon?.attributes &&
                professional?.sessionPrice -
                  handleCouponType(
                    coupon?.attributes,
                    professional?.sessionPrice,
                  ) ===
                  0 ? (
                  <Fragment></Fragment>
                ) : (
                  <Fragment>
                    <RowLine style={{ justifyContent: "center" }}>
                      {!pixData &&
                        ["credit_card", "all_payment", null].includes(
                          professional?.preferentialPayment,
                        ) && (
                          <CardButtonMethod
                            checked={values.paymentMethod === "credit_card"}
                            onClick={() =>
                              setFieldValue("paymentMethod", "credit_card")
                            }
                          >
                            Cartão de crédito
                          </CardButtonMethod>
                        )}
                      {["pix", "all_payment", null].includes(
                        professional?.preferentialPayment,
                      ) && (
                        <CardButtonMethod
                          checked={values?.paymentMethod === "pix" || pixData}
                          onClick={() => setFieldValue("paymentMethod", "pix")}
                        >
                          PIX
                        </CardButtonMethod>
                      )}
                    </RowLine>

                    {values?.paymentMethod === "credit_card" && !pixData && (
                      <Hr style={{ width: "90%" }} />
                    )}

                    {values?.paymentMethod === "credit_card" && (
                      <WrapperPayment>
                        <ContainerCardPayment>
                          {loading ? (
                            <LoaderSpinner />
                          ) : (
                            <ListCardPayment
                              data={creditCards}
                              renderItem={item => (
                                <CreditCardListItem
                                  key={item.attributes.id}
                                  data={item}
                                  selected={selected}
                                  setSelected={setSelected}
                                  handleDeleteCard={handleDeleteCard}
                                  exclude
                                />
                              )}
                            />
                          )}
                        </ContainerCardPayment>

                        <ButtonAddCreditCard
                          secondary={"true"}
                          onClick={() =>
                            history.push({
                              pathname: `/${user.type}/subscription/add_new_payment`,
                              state: { id: professional.id },
                            })
                          }
                        >
                          Adicionar forma de pagamento
                        </ButtonAddCreditCard>
                      </WrapperPayment>
                    )}
                    {values?.paymentMethod === "pix" && pixData && (
                      <PayPix data={pixData} />
                    )}
                  </Fragment>
                )}
              </PaymentMethod>

              {!pixData && (
                <Fragment>
                  <Hr />
                  <RowButtons style={{ padding: "0 2rem" }}>
                    {coupon?.attributes &&
                    professional?.sessionPrice -
                      handleCouponType(
                        coupon?.attributes,
                        professional?.sessionPrice,
                      ) ===
                      0 ? (
                      <PaymentButton
                        onClick={() => {
                          handleContinue();
                          setLoading(true);
                        }}
                      >
                        Realizar agendamento
                      </PaymentButton>
                    ) : values?.paymentMethod === "pix" ? (
                      <PaymentButton
                        onClick={() => {
                          handleContinue();
                          setLoading(true);
                        }}
                        checked={values?.paymentMethod}
                      >
                        Realizar pagamento com pix
                      </PaymentButton>
                    ) : (
                      <PaymentButton
                        disabled={creditCards?.length === 0}
                        onClick={() => {
                          if (values.creditCardId === "") {
                            toast.error("Cadastre um cartão");
                          } else {
                            handleContinue();
                          }
                        }}
                        checked={values.paymentMethod}
                      >
                        Realizar pagamento
                      </PaymentButton>
                    )}
                    <CancelButton
                      onClick={() => {
                        setValues({});
                        setScheduleInfo(null);
                        history.push("/client/professionals");
                      }}
                    >
                      Cancelar agendamento
                    </CancelButton>
                  </RowButtons>
                </Fragment>
              )}
            </PaymentCreditCard>
          ) : (
            <HeaderWrapperResum>
              <WrapperTitleResum>Método de pagamento Externo</WrapperTitleResum>
              <FlexStartContainer>
                <FlexStartText bold style={{ fontSize: "1rem" }}>
                  O pagamento desse agendamento deverá ser feito diretamente com
                  a clínica!
                </FlexStartText>
              </FlexStartContainer>

              <RowButtons>
                <PaymentButton
                  onClick={() => handleContinue()}
                  checked={values.paymentMethod}
                >
                  Realizar agendamento
                </PaymentButton>
                <CancelButton
                  onClick={() => {
                    setValues({});
                    setScheduleInfo(null);
                    history.push("/client/professionals");
                  }}
                >
                  Cancelar agendamento
                </CancelButton>
              </RowButtons>
            </HeaderWrapperResum>
          )}
        </PaymentWrapper>
      )}
    </PaymentContainer>
  );
}

export function ScheduleForm() {
  const history = useHistory();
  const { user } = useAuth();
  const { id } = useParams();
  const { createEvent, scheduleInfo, setScheduleInfo } = useEvent();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [professional, setProfessional] = useState(null);
  const [pixData, setPixData] = useState(null);
  const [modalAlert, setModalAlert] = useState(false);

  const validateInfo = [
    user?.firstName,
    user?.lastName,
    user?.birthday,
    user?.phone,
    user?.documentNumber,
  ]?.every(info => info !== null && info !== undefined && info !== "");

  const validateAddress = [
    user?.address?.id,
    user?.address?.street,
    user?.address?.street_number,
    user?.address?.cep,
    user?.address?.state,
    user?.address?.country,
    user?.address?.city,
    user?.address?.neighborhood,
  ]?.every(info => info !== null && info !== undefined && info !== "");

  const validateGuardian = [
    user?.legalGuardianFullName,
    user?.legalGuardianEmail,
    user?.legalGuardianPhone,
    user?.legalGuardianDocumentNumber,
  ]?.every(info => info !== null && info !== undefined && info !== "");

  const schema = Yup.object({
    duration: Yup.string().required("Duração do agendamento é obrigatório!"),
    startDate: Yup.string().required("Data do agendamento é obrigatório!"),
    startTime: Yup.string().required("Horário o agendamento é obrigatório!"),
    appointmentType: Yup.string().required(
      "Tipo de agendamento é obrigatório!",
    ),
    postPaid: Yup.boolean(),
    externalPayment: Yup.boolean(),
  });

  const form = useFormik({
    initialValues: {
      startDate: scheduleInfo?.startDate || "",
      startTime: scheduleInfo?.startTime || "",
      appointmentType: scheduleInfo?.appointmentType || "",
      paymentMethod: scheduleInfo?.paymentMethod || "credit_card",
      creditCardId: scheduleInfo?.creditCardId || "",
      couponId: scheduleInfo?.couponId || null,
      recurrent: scheduleInfo?.recurrent || false,
      duration: scheduleInfo?.duration || "",
      frequency: scheduleInfo?.frequency || "",
      fullAmountCovered: null,
      postPaid: scheduleInfo?.postPaid || false,
      externalPayment: scheduleInfo?.externalPayment || false,
      postPaidDay: scheduleInfo?.postPaidDay || 0,
      individualScheduling: scheduleInfo?.individualScheduling || false,
      monthlySubscription: scheduleInfo?.monthlySubscription || false,
      consultingPackage: scheduleInfo?.consultingPackage || false,
    },
    validationSchema: schema,
    onSubmit: values => {
      submitForm(values);
    },
  });

  const { values, setValues } = form;

  const submitForm = async formValues => {
    setLoading(true);
    try {
      const { success, error, data } = await createEvent(formValues);

      if (success && !error) {
        if (formValues?.fullAmountCovered) {
          toast.success("Agendamento criado!");
          history.push("/client/dashboard");
        } else if (formValues?.paymentMethod === "pix") {
          toast.success("Faça o pagamento para confirmar este agendamento!");
          setPixData(data?.data);
        } else {
          toast.success("Agendamento criado!");
          history.push("/client/dashboard");
        }
        form.resetForm();
      } else {
        toast.error("Não foi possível criar o agendamento!");
      }
    } catch (error) {
      toast.error("Não foi possível criar o agendamento!");
    } finally {
      setLoading(false);
    }
  };

  function nextPage() {
    setScheduleInfo(values);
    setStep(step + 1);
  }

  useEffect(() => {
    if (Number(scheduleInfo?.professional?.id) === Number(id)) {
      if (
        validateInfo &&
        validateAddress &&
        (user?.needLegalGuardian ? validateGuardian : true)
      ) {
        setStep(1);
      } else {
        setModalAlert(true);
      }
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const fetchProfessional = async () => {
      setLoading(true);
      try {
        const {
          data: { data: professional },
        } = await api.get(`/${user?.type}s/professionals/${id}`, {
          signal: controller.signal,
        });

        if (professional) {
          setProfessional({ ...professional.attributes, id: id });
          setValues({
            ...values,
            duration:
              professional?.attributes?.availabilityRule?.appointmentDuration,
            professional,
          });
        }
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error("Não foi possível carregar esse profissional");
      } finally {
        setLoading(false);
      }
    };
    fetchProfessional();

    return () => controller.abort();
  }, [id]);

  return (
    <Main>
      {loading ? (
        <LoaderSpinner logo />
      ) : professional?.id ? (
        <FormikProvider value={form}>
          {professional && step === 0 && !pixData && (
            <CreateEvent
              form={form}
              next={nextPage}
              professional={professional}
              user={user}
            />
          )}
          {professional && step === 1 && (
            <PayEvent
              form={form}
              next={submitForm}
              professional={professional}
              pixData={pixData}
              loading={loading}
              setLoading={setLoading}
            />
          )}
        </FormikProvider>
      ) : (
        <EmptyContainer>
          <EmptyImage />
          <EmptyTitle>Profissional Indisponivel</EmptyTitle>
          <EmptyText>
            Esse profissional não está disponivel em nossa plataforma.
          </EmptyText>
        </EmptyContainer>
      )}

      {modalAlert && (
        <Modal open>
          <BoxWrapperSchedule>
            <IconArea style={{ padding: "1rem 1rem 0 0" }}>
              <CloseIcon onClick={() => setModalAlert(false)} />
            </IconArea>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <WarningIcon style={{ marginRight: "0.5rem" }} />
              <BigFont
                style={{
                  fontWeight: "700",
                  fontSize: "1.5rem",
                }}
              >
                Aviso!
              </BigFont>
            </div>
            {!user?.id ? (
              <BigFont style={{ fontWeight: "700", margin: "1.5rem 5rem" }}>
                Para agendar uma consulta, é necessário estar logado na
                plataforma.
              </BigFont>
            ) : (
              <BigFont style={{ fontWeight: "700", margin: "1.5rem 5rem" }}>
                {`Para agendar uma consulta, é necessário ${
                  user?.needLegalGuardian && !validateGuardian
                    ? "cadastrar um responsável."
                    : "completar seu cadastro."
                }`}
              </BigFont>
            )}
            <Hr />
            <ButtonContainer
              style={{
                width: "100%",
                marginTop: "0.5rem",
                marginBottom: "2rem",
                justifyContent: "center",
              }}
            >
              <ScheduleButton
                style={{ width: "70%" }}
                onClick={() => history.push("/client/config")}
              >
                Completar cadastro
              </ScheduleButton>
            </ButtonContainer>
          </BoxWrapperSchedule>
        </Modal>
      )}
    </Main>
  );
}
