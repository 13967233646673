/* eslint-disable react-hooks/exhaustive-deps */
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import useAuth from "../../hooks/auth";
import useEvent from "../../hooks/event";
import {
  BREAKPOINT_MD,
  BoxScroll,
  BoxScrollNavBar,
} from "../../styles/globalStyles";
import useWindowSize from "../../utils/WindowSize";
import { HeaderItemText, UserAvatar, UserImage } from "../Header/styles";
import ModalAcceptanceTerm, {
  AcceptanceNewTerm,
} from "../Modals/ModalAcceptanceTerm";
import sideBarDatas from "./sideBarData";
import {
  Container,
  IconArea,
  Main,
  MenuSideBar,
  MenuSideBarWrapper,
  NavBarItemList,
  NavBarItemText,
  NavHelpButton,
  NavMenuItems,
  NavMenuMobile,
  NavMenuWeb,
  ProfileImage,
} from "./styles";

export default function Sidebar({ children }) {
  const location = useLocation();
  const [sidebar, setSidebar] = useState(false);
  const [activate, setActivate] = useState(location.pathname);
  const { signOut, user, updateProfessional, setLoading } = useAuth();
  const { setScheduleInfo } = useEvent();
  const { width } = useWindowSize();
  const defaultType = localStorage.getItem("type");
  const type = user.type || defaultType;
  const planType = user?.subscription?.pagarmePlan;
  const [acceptTerm, setAcceptTerm] = useState(
    user?.type === "professional" && (!user?.term?.id || user?.term?.id === 1),
  );
  const [newTerm, setNewTerm] = useState(false);

  useEffect(() => {
    setActivate(location.pathname);
  }, [location.pathname]);

  const form = useFormik({
    initialValues: {
      read: false,
      accepted: false,
      termId: "",
    },
    onSubmit: async values => {
      if (values.read && values.accepted) {
        setLoading(true);
        try {
          const success = await updateProfessional(
            { termId: values.termId },
            user?.id,
          );
          if (success) toast.success("Termo de uso aceito com sucesso");
          else toast.error("Não foi possível atualizar o termo de uso");
        } catch (error) {
          toast.error("Não foi possível atualizar o termo de uso");
        } finally {
          setNewTerm(false);
          setLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    if (!form.values.read && form.values.accepted) {
      toast.info("Para prosseguir, leia e aceite o termo de uso.", {
        position: "top-center",
        autoClose: 5000,
      });
      form.setFieldValue("accepted", false);
    }
  }, [form.values.accepted]);

  const allSideBarData = sideBarDatas(user);

  const sideBarData = allSideBarData?.filter(item =>
    user?.clinic?.id ? item.title !== "Planos de Assinatura" : item,
  );

  const pageMeeting =
    width > BREAKPOINT_MD && location.pathname.includes("meeting");

  const pageType = pageMeeting
    ? "true"
    : type === "professional"
    ? "true"
    : "false";

  const navListClientWeb = sideBarData?.filter(item =>
    type === "client" && width > BREAKPOINT_MD
      ? item.title !== "Configurações" &&
        item.title !== "Perfil Público" &&
        item.title !== "Perfil" &&
        item.title !== "Profissionais" &&
        item.title !== "Planos de Assinatura" &&
        item.title !== "Extrato de Pagamentos" &&
        item.title !== "Clientes"
      : item.title !== "Perfil Público" &&
        item.title !== "Perfil" &&
        item.title !== "Profissionais" &&
        item.title !== "Planos de Assinatura" &&
        item.title !== "Extrato de Pagamentos" &&
        item.title !== "Clientes",
  );

  const navListProfessionalWeb = sideBarData?.filter(item =>
    type === "professional" && width > BREAKPOINT_MD
      ? item.title !== "Perfil Público" &&
        item.title !== "Perfil" &&
        item.title !== "Profissionais" &&
        item.title !== "Buscar profissionais" &&
        item.title !== "Configurações"
      : item.title !== "Buscar profissionais" &&
        item.title !== "Perfil" &&
        item.title !== "Profissionais",
  );

  const navListClinicWeb = sideBarData?.filter(item =>
    type === "clinic" && width > BREAKPOINT_MD
      ? item.title !== "Perfil Público" &&
        item.title !== "Buscar profissionais" &&
        item.title !== "Configurações" &&
        item.title !== "Conteúdos" &&
        item.title !== "Mensagens"
      : item.title !== "Buscar profissionais" &&
        item.title !== "Conteúdos" &&
        item.title !== "Mensagens",
  );

  const getNavlist = () => {
    if (type === "client") {
      return navListClientWeb;
    } else if (type === "professional") {
      if (planType === "free") {
        return navListProfessionalWeb.filter(
          item => item.title !== "Extrato de Pagamentos",
        );
      }
      return navListProfessionalWeb;
    } else if (type === "clinic") {
      return navListClinicWeb;
    }
  };

  const navlist = getNavlist();

  function ItemList({ item }) {
    return item.href ? (
      <NavHelpButton title="WhatsApp" href={item.href} target="_blank">
        <IconArea className={item.className}>{item.icon}</IconArea>
        <NavBarItemText className={item.className}>{item.title}</NavBarItemText>
      </NavHelpButton>
    ) : (
      <NavBarItemList
        active={item.path === activate ? "true" : "false"}
        onClick={() => {
          if (item.title === "Sair") {
            setScheduleInfo(null);
            signOut();
          } else setSidebar(false);
        }}
        title={item.title}
        to={{ pathname: item.path }}
        typepage={pageType}
      >
        <IconArea className={item.className}>{item.icon}</IconArea>

        <NavBarItemText className={item.className}>{item.title}</NavBarItemText>
      </NavBarItemList>
    );
  }

  function NavBarMobile({ ...props }) {
    const { isVisible } = props;

    return (
      isVisible && (
        <NavMenuMobile typepage={pageType}>
          <MenuSideBarWrapper>
            <MenuSideBar onClick={() => setSidebar(false)}>
              <MenuOpenIcon
                fontSize="large"
                style={{
                  color: pageType === "true" ? "#000" : "#fff",
                }}
              />
            </MenuSideBar>
          </MenuSideBarWrapper>
          <BoxScroll padding="3rem">
            <NavMenuItems>
              <ProfileImage
                typepage={pageType}
                meeting={
                  width > BREAKPOINT_MD && location.pathname.includes("meeting")
                }
              >
                <UserAvatar>
                  <UserImage size="100%" src={user?.avatar} />
                </UserAvatar>
                <HeaderItemText>{`${user?.firstName}`}</HeaderItemText>
              </ProfileImage>
              {navlist?.map(item => (
                <ItemList key={item.id} item={item} />
              ))}
            </NavMenuItems>
          </BoxScroll>
        </NavMenuMobile>
      )
    );
  }

  function NavBarWeb() {
    return (
      <NavMenuWeb active={sidebar}>
        <BoxScrollNavBar>
          <NavMenuItems>
            {navlist?.map(item => (
              <ItemList key={item.id} item={item} />
            ))}
          </NavMenuItems>
        </BoxScrollNavBar>
      </NavMenuWeb>
    );
  }

  if (newTerm) {
    return <AcceptanceNewTerm form={form} />;
  }

  if (acceptTerm) {
    return (
      <ModalAcceptanceTerm
        handleModal={setAcceptTerm}
        setNewTerm={setNewTerm}
      />
    );
  }

  return (
    <Main meeting={activate.includes("meeting")}>
      <Header sidebar={sidebar} setSidebar={setSidebar} />
      {width <= BREAKPOINT_MD || activate.includes("meeting") ? (
        <NavBarMobile isVisible={sidebar} />
      ) : (
        <NavBarWeb />
      )}
      <Container meeting={activate.includes("meeting")}>{children}</Container>
    </Main>
  );
}
