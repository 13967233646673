export default function getPlanName(plan) {
  switch (plan) {
    case 'free':
      return 'Gratuito';
    case 'platinum':
      return 'Platina';
    case 'gold':
      return 'Ouro';
    case 'silver':
      return 'Prata';
    default:
      return 'Gratuito';
  }
}
