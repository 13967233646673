import { Modal, Typography } from '@material-ui/core';
import valid from 'card-validator';
import { FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import useAuth from '../../../hooks/auth';
import useEvent from '../../../hooks/event';
import api from '../../../services/api';
import { CloseIcon, IconAreaModal } from '../../../styles/globalStyles';
import getPlanName from '../../../utils/getPlanName';
import PaymentForm from '../PaymentForm';
import {
  BoxWrapper,
  Button,
  CheckBoxWrapper,
  LinkWrapper,
  Main,
} from '../styles';
import TablePlan from './TablePlan';

export function SubscribeCard({ ...props }) {
  const { item, values } = props;
  return (
    <CheckBoxWrapper
      full={values.planName === (item.value?.data || item.title)}
      {...props}
    >
      <LinkWrapper
        full={values.planName === item.value?.data}
        href="https://allminds.app/specialist#plans"
        target="_blank"
      >
        saiba mais
      </LinkWrapper>
    </CheckBoxWrapper>
  );
}

export function SubscribeFormik({ ...props }) {
  const { user } = useAuth();
  const { infoCardCredit } = useEvent();
  const {
    plan,
    currentPlan,
    setAuthData,
    creditCards,
    handleContinue,
    loading,
    setLoading,
  } = props;
  const [modal, setModal] = useState(false);
  const [next, setNext] = useState(false);

  const schema = Yup.object({
    planName: Yup.string().required('Plano é obrigatório.'),
    payment_method: Yup.string().required('Forma de pagamento é obrigatório.'),
    cardNumber: Yup.string().test(
      'test-number',
      'Cartão inválido',
      (value) => valid.number(value).isValid
    ),
    cardHolderName: Yup.string().required('Nome é obrigatório.'),
    cardCvv: Yup.string().test(
      'test-cvv',
      'Código de segurança inválido',
      (value) => valid.cvv(value).isValid
    ),
    cardExpiration_date: Yup.string().test(
      'test-date',
      'Data inválida',
      (value) => valid.expirationDate(value).isValid
    ),
  });

  const handleModal = (planName) => {
    const creditCardDefault = creditCards?.find(
      (item) => item.attributes.default === true
    );
    const validCard = infoCardCredit || creditCardDefault?.id;
    const validPlan =
      user?.subscription?.pagarmePlan?.includes('founder_gold') ||
      user?.subscription?.pagarmePlan?.includes('founder_platinum');

    if (planName === 'free') {
      setModal(true);
    } else {
      if (validCard && !validPlan) {
        setNext(true);
        setModal(true);
      } else {
        validPlan
          ? toast.error('Atualização de plano indisponível')
          : toast.error(
              'É necessário adicionar ou selecionar um cartão de crédito.'
            );
      }
    }
  };

  const handleClick = async (values) => {
    setLoading(true);
    const creditCardDefault = creditCards?.find(
      (item) => item.attributes.default === true
    );

    try {
      setModal(false);
      if (values.planName === 'free') {
        const subscription = {
          data: {
            type: 'subscription',
            attributes: {
              planName: 'free',
            },
          },
        };
        if (user.subscription.id) {
          const { data } = await api.put(
            `${user.type}s/subscriptions/${user.subscription.id}`,
            subscription
          );
          setAuthData({ ...user, subscription: data.data.attributes });
        } else {
          const { data } = await api.post(
            `${user.type}s/subscriptions`,
            subscription
          );
          setAuthData({ ...user, subscription: data.data.attributes });
        }
        toast.success(
          `Plano gratuito selecionado! Por favor autentique-se novamente.`
        );
      } else {
        if (next) {
          const subscription = {
            data: {
              type: 'subscription',
              attributes: {
                planName: values.planName,
                creditCardId: infoCardCredit || creditCardDefault?.id,
              },
            },
          };
          if (user.subscription.id) {
            await api.put(
              `${user.type}s/subscriptions/${user.subscription.id}`,
              subscription
            );
          } else {
            await api.post(`${user.type}s/subscriptions`, subscription);
          }
          if (values.planName === 'gold') {
            toast.success(
              `Plano Ouro assinado com sucesso! Por favor autentique-se novamente.`
            );
          } else if (values.planName === 'platinum') {
            toast.success(
              `Plano Platina assinado com sucesso! Por favor autentique-se novamente.`
            );
          }
        }
      }
      setLoading(false);
    } catch (error) {
      toast.error('Não foi possível cadastrar assinatura');
      setLoading(false);
      setModal(false);
    }
  };

  const handleValues = (values) => {
    values.cardNumber = values.cardNumber.replace(/\s+/g, '');
    values.cardExpiration_date = values.cardExpiration_date.replace(/[/]/g, '');
    handleContinue(values);
  };

  const form = useFormik({
    initialValues: {
      planName: getPlanName(user?.subscription?.pagarmePlan),
      payment_method: 'Cartão de Crédito',
      cardNumber: '',
      cardHolderName: '',
      cardCvv: '',
      cardExpiration_date: '',
      setDefault: false,
      cpf: '',
    },
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);
      handleValues(values);
    },
  });

  const { handleSubmit, values, setFieldValue } = form;

  return (
    <Main className="subscribe">
      <FormikProvider value={form}>
        {plan ? (
          <section className="update-plan">
            <TablePlan
              currentPlan={currentPlan}
              handleModal={handleModal}
              setFieldValue={setFieldValue}
              values={values}
            />
            <div className="description">
              <p>
                ➀ : Aumento de rendimentos por meio da venda de treinamentos e
                cursos para a allminds e seus usuarios.
              </p>
              <p>
                ➁ : Na página de busca por profissionais ("Nossos
                Especialistas"), quando os usuários fazem uma pesquisa, o seu
                perfil aparecerá no topo, meio ou embaixo na listagem de
                profissionais, a depender do seu plano e dos filtros
                selecionados pelo usuário.
              </p>
              <p>
                ➂ : Custo de processamento de pagamento varia de acordo com a
                forma de pagamento selecionada e é abatido do valor pago pelo
                usuario (cartão de crédito a vista - 3,2% ; Boleto - R$ 3,19 e
                Pix - 1,05% ). Saques tem uma taxa de R$ 3,67, exceto para o
                Bradesco (gratuito).
              </p>
            </div>
          </section>
        ) : (
          <form className="subscribe-form" onSubmit={handleSubmit}>
            <section className="--input-wrapper">
              <PaymentForm form={form} loading={loading} />
            </section>
          </form>
        )}
      </FormikProvider>

      {modal && (
        <Modal open>
          <BoxWrapper>
            <IconAreaModal>
              <CloseIcon
                onClick={() => {
                  setModal(false);
                }}
              />
            </IconAreaModal>
            <div className="--content">
              <Typography variant="h6">
                Tem certeza que deseja alterar o seu plano?
              </Typography>
              <Typography variant="p" sx={{ mt: 2 }}>
                {values?.planName === 'free'
                  ? 'Você esta alterando seu plano para gratuito. Desejá continuar?'
                  : 'A alteração pode gerar uma nova cobrança proporcional'}
              </Typography>
            </div>
            <div className="--buttons">
              <Button
                onClick={() => {
                  setModal(false);
                  setFieldValue('planName', '');
                }}
                secondary={'true'}
              >
                Cancelar
              </Button>
              <Button onClick={() => handleClick(values)}>Confirmar</Button>
            </div>
          </BoxWrapper>
        </Modal>
      )}
    </Main>
  );
}
