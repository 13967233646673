import React from 'react';
import IconWarning from '../../../images/alert-triangle-icon.svg';
import theme from '../../../theme';
import { SmallButton } from '../../Button';
import {
  Container,
  ContainerButtons,
  Icon,
  IconArea,
  TextAlert,
  TitleWrapper,
} from './styles';

export function ConfirmationAlert({ message, goBack, confirm, buttonsHeigth }) {
  return (
    <Container>
      <TitleWrapper>
        <IconArea>
          <Icon src={IconWarning} alt="Atenção" />
        </IconArea>
        <TextAlert>{message}</TextAlert>
      </TitleWrapper>
      <ContainerButtons>
        <SmallButton
          width="30%"
          height={buttonsHeigth}
          color={theme.primary}
          type="button"
          title="Voltar"
          onClick={() => goBack()}
        />
        <SmallButton
          width="30%"
          height={buttonsHeigth}
          color={theme.pink}
          type="button"
          title="Confirmar"
          onClick={(data) => confirm(data)}
        />
      </ContainerButtons>
    </Container>
  );
}
