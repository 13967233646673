/* eslint-disable react-hooks/exhaustive-deps */
import {
  TextField,
  ThemeProvider,
  Tooltip,
  createTheme,
} from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useAuth from '../../../hooks/auth';
import { addZero } from '../../../services';
import api from '../../../services/api';
import { PtBr } from '../../../services/pt_br';
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  CALENDAR_COLUMN_LG,
  CALENDAR_COLUMN_MD,
  CALENDAR_COLUMN_SM,
  CALENDAR_COLUMN_XL,
} from '../../../styles/globalStyles';
import useWindowSize from '../../../utils/WindowSize';
import CalendarComponent from './components';
import {
  ArrowLeft,
  ArrowRight,
  BodyContainer,
  ButtonsContainer,
  CalendarButton,
  DateInfo,
  InfoDay,
  InfoMonth,
  InputWrapper,
  MenuHeader,
  SearchClose,
  SearchContainer,
  SearchContent,
  SearchContentAvatar,
  SearchContentMain,
} from './styles';
import theme from '../../../theme';

const themeText = createTheme({
  palette: {
    primary: {
      main: '#38382f',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: theme.newPrimary,
        },
        '&$focused $notchedOutline': {
          borderColor: theme.newPrimary,
          boxShadow: 'none',
        },
      },
    },
  },
});

function AutocompleteInput({ ...props }) {
  const { professionals, handleFilterSchedule } = props;
  const [inputValue, setInputValue] = useState('');
  const [showOptions, setShowOptions] = useState(false);

  const resetFilter = () => {
    setInputValue('');
    setShowOptions(false);
    handleFilterSchedule('');
  };

  const handleChange = ({ target }) => {
    setInputValue(target.value);
    if (target.value === '') {
      setTimeout(() => {
        resetFilter();
      }, 300);
    } else setShowOptions(true);
  };

  const filterProfesionals = professionals?.filter((prof) =>
    prof.firstName.toLowerCase().includes(inputValue.toLowerCase())
  );

  return (
    <SearchContainer>
      <InputWrapper>
        <ThemeProvider theme={themeText}>
          <TextField
            variant="outlined"
            label="Buscar profissional"
            value={inputValue}
            onChange={handleChange}
            fullWidth
            sx={{ bgcolor: theme.newPrimaryAlt }}
          />
        </ThemeProvider>
        {inputValue !== '' && (
          <Tooltip title="Limpar filtro">
            <SearchClose type="button" onClick={resetFilter}>
              X
            </SearchClose>
          </Tooltip>
        )}
      </InputWrapper>

      <SearchContentMain isOpen={showOptions}>
        {filterProfesionals?.map((professional) => (
          <SearchContent
            key={professional.id}
            type="button"
            onClick={() => {
              handleFilterSchedule(professional.firstName);
              setInputValue(
                `${professional.firstName} ${professional.lastName}`
              );
              setTimeout(() => {
                setShowOptions(false);
              }, 300);
            }}
          >
            <SearchContentAvatar
              src={professional.avatar}
              alt="Foto do profissional"
            />
            {`${professional.firstName} ${professional.lastName}`}
          </SearchContent>
        ))}
      </SearchContentMain>
    </SearchContainer>
  );
}

export default function CalendarScreen() {
  const { user } = useAuth();
  const { width } = useWindowSize();
  const [date, setDate] = useState(moment().toDate());
  const [daysWeek, setDaysWeek] = useState(0);
  const [schedules, setSchedules] = useState([]);
  const [filterSchedules, setFilterSchedules] = useState(null);
  const [schedulesClinic, setSchedulesClinic] = useState([]);
  const defaultType = localStorage.getItem('type');
  const [loading, setLoading] = useState(true);
  const type = user.type || defaultType;

  useEffect(() => {
    const controller = new AbortController();
    const getEvents = async () => {
      try {
        const { data } = await api.get(`/${type}s/schedules`, {
          signal: controller.signal,
        });
        if (data.data) {
          setSchedules(data.data);
          return true;
        }
        throw new Error('Não foi possível listar os agendamentos');
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error('Não foi possível listar os agendamentos');
        return false;
      } finally {
        setLoading(false);
      }
    };
    getEvents();

    return () => controller.abort();
  }, [date]);

  useEffect(() => {
    const dayColumn = () => {
      if (width <= BREAKPOINT_SM) return CALENDAR_COLUMN_SM;
      if (width <= BREAKPOINT_MD) return CALENDAR_COLUMN_MD;
      if (width <= BREAKPOINT_LG) return CALENDAR_COLUMN_LG;
      if (width > BREAKPOINT_LG) return CALENDAR_COLUMN_XL;
    };
    setDaysWeek(dayColumn());
  }, [width]);

  useEffect(() => {
    const primaryWeekDay = (days) => {
      let day = moment().format('ddd');
      if (days <= 3) return moment(day, 'ddd').toDate();
      while (day !== 'dom') {
        day = moment(day, 'ddd').subtract(1, 'days').format('ddd');
      }
      return moment(day, 'ddd').toDate();
    };
    setDate(primaryWeekDay(daysWeek));
  }, [daysWeek]);

  const sumSubDate = (days, method) =>
    method === 'add'
      ? moment(date, 'YYYY-MM-DD').add(days, 'days').toDate()
      : moment(date).subtract(days, 'days').toDate();

  const lastDate = sumSubDate(daysWeek - 1, 'add');

  const getProfessionals = useCallback(() => {
    const professionals = schedules.map(
      (schedule) => schedule.attributes.professional
    );
    return Array.from(new Set(professionals.map(JSON.stringify))).map(
      JSON.parse
    );
  }, [schedules]);

  const professionals = getProfessionals();

  const handleFilterSchedule = useCallback(
    (value) => {
      if (value === '') {
        setFilterSchedules(null);
      } else {
        const filter = schedules.filter((schedule) =>
          schedule.attributes.professional.firstName
            .toLowerCase()
            .includes(value.toLowerCase())
        );
        setFilterSchedules(filter);
      }
    },
    [schedules]
  );

  useEffect(() => {
    setSchedulesClinic(filterSchedules || schedules);
  }, [handleFilterSchedule, date, filterSchedules, schedules]);

  return (
    <BodyContainer>
      <AutocompleteInput
        professionals={professionals}
        handleFilterSchedule={handleFilterSchedule}
      />

      <MenuHeader>
        <ButtonsContainer>
          <CalendarButton onClick={() => setDate(sumSubDate(daysWeek, 'sub'))}>
            <ArrowLeft />
          </CalendarButton>

          {daysWeek === 1 ? (
            <DateInfo>
              <InfoMonth>
                {PtBr.months_name[date.getMonth()].toUpperCase()}
              </InfoMonth>
            </DateInfo>
          ) : (
            <DateInfo>
              <InfoMonth>
                {lastDate.getMonth() === date.getMonth()
                  ? PtBr.months_name[date.getMonth()].toUpperCase()
                  : `${PtBr.months_name[
                      date.getMonth()
                    ].toUpperCase()} / ${PtBr.months_name[
                      lastDate.getMonth()
                    ].toUpperCase()}`}
              </InfoMonth>
              <InfoDay>
                {daysWeek === 1
                  ? `${addZero(date.getDate())}`
                  : `${addZero(date.getDate())} a ${addZero(
                      lastDate.getDate()
                    )}`}
              </InfoDay>
            </DateInfo>
          )}

          <CalendarButton onClick={() => setDate(sumSubDate(daysWeek, 'add'))}>
            <ArrowRight />
          </CalendarButton>
        </ButtonsContainer>
      </MenuHeader>
      <CalendarComponent
        date={date}
        dayColumn={daysWeek}
        schedules={schedulesClinic}
        loading={loading}
        setLoading={setLoading}
      />
    </BodyContainer>
  );
}
