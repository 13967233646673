import data from '@emoji-mart/data';
import i18n from '@emoji-mart/data/i18n/pt.json';
import Picker from '@emoji-mart/react';
import { InsertEmoticon } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { IconButton, Popover } from '@mui/material';
import React, { useState } from 'react';
import { FieldLabel } from '../SelectList/styles';
import {
  BigContainer,
  CheckInput,
  Container,
  Content,
  EmojiInput,
  EmojiPickerContainer,
  StyledInput,
  StyledTextArea,
  StyledTimeField,
  StyledTimeInput,
  Wrapper,
} from './styles';

export function TextInput({ ...props }) {
  return (
    <Container>
      <StyledInput {...props} />
    </Container>
  );
}

export function EmojiInputWithPicker({ ...props }) {
  const { value, onEmojiSelect, onClick, ...rest } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenPicker = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePicker = () => {
    setAnchorEl(null);
  };

  return (
    <EmojiPickerContainer>
      <EmojiInput type="text" value={value} {...rest} />
      <IconButton style={{ marginRight: '0.35rem' }} onClick={onClick}>
        <SendIcon />
      </IconButton>
      <IconButton onClick={handleOpenPicker}>
        <InsertEmoticon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePicker}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Picker
          set="emojione"
          data={data}
          onEmojiSelect={(emoji) => {
            onEmojiSelect(emoji.native);
            handleClosePicker();
          }}
          i18n={i18n}
        />
      </Popover>
    </EmojiPickerContainer>
  );
}

export function FixedTextInput({ ...props }) {
  const { label } = props;
  return (
    <Container style={{ marginBottom: '1rem', width: '100%' }}>
      {label && <label>{label}</label>}
      <StyledInput {...props} />
    </Container>
  );
}

export function OpenedTextInput({ ...props }) {
  const { title, label, size } = props;
  return (
    <BigContainer size={size}>
      {title && <label>{title}</label>}
      <Wrapper>
        {label && <label>{label}</label>}
        <StyledTextArea {...props} />
      </Wrapper>
    </BigContainer>
  );
}

export function TimeInput({ ...props }) {
  const { hour, minutes, minutesName, hourName } = props;
  return (
    <Container>
      <StyledTimeInput
        min={0}
        max={24}
        type="number"
        name={hourName}
        value={hour}
        {...props}
      />
      <label>h</label>
      <StyledTimeInput
        type="number"
        max={60}
        min={0}
        name={minutesName}
        value={minutes}
        {...props}
      />
      <label>min</label>
    </Container>
  );
}

export function CheckBoxInput({ ...props }) {
  const { label, defaultChecked } = props;
  return (
    <Wrapper>
      <CheckInput {...props} type="checkbox" defaultChecked={defaultChecked} />
      <label>{label}</label>
    </Wrapper>
  );
}

export function NewCheckBoxInput({ ...props }) {
  const { label, ...rest } = props;
  return (
    <Wrapper>
      <FieldLabel htmlFor={label}>
        <CheckInput id={label} {...rest} type="checkbox" left={true} />
        {label}
      </FieldLabel>
    </Wrapper>
  );
}

export function SimpleTimeInput({ ...props }) {
  return <StyledTimeField {...props} />;
}

export function TimeSelect({ ...props }) {
  const { initial, final } = props;
  return (
    <Content>
      <div className="--time">
        <StyledTimeField initTime={initial} {...props} />
      </div>
      <label className="--signal">-</label>
      <div className="--time">
        <StyledTimeField mountFocus="true" initTime={final} {...props} />
      </div>
    </Content>
  );
}
