/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flatlist } from '../../../components/List';
import { PacientListItem } from '../../../components/ListItens';
import api from '../../../services/api';
import useWindowSize from '../../../utils/WindowSize';
import { Main } from './styles';

export default function ClientList() {
  const [patients, setPatients] = useState([]);
  const { width } = useWindowSize();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const fetchPatients = async () => {
      try {
        const { data } = await api.get('/clinics/patients', {
          signal: controller.signal,
        });
        setPatients(data.data);
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error('Não foi possível carregar lista de pacientes');
      } finally {
        setLoading(false);
      }
    };
    fetchPatients();

    return () => controller.abort();
  }, []);

  return (
    <Main>
      <Flatlist
        type="patient"
        data={patients}
        filterObject={(item) => item.attributes.client.firstName}
        placeholder="Pesquise um Cliente"
        renderItem={(item, index) => (
          <PacientListItem key={index} data={item} width={width} clinic />
        )}
        loading={loading}
      />
    </Main>
  );
}
