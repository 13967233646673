/* eslint-disable react-hooks/exhaustive-deps */
import { FormikProvider, useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { StaticButton } from "../../../components/Button";
import LoaderSpinner from "../../../components/LoaderSpinner";
import useAuth from "../../../hooks/auth";
import api from "../../../services/api";
import validateCPF from "../../../utils/validateCPF";
import Availability, { getSortedWorkdays } from "../../Register/Availability";
import {
  UpdateAdress,
  UpdateBankAccount,
  UpdateBasicData,
  UpdatePassword,
  UpdateProfessionalInfos,
} from "./AccountConfig";
import Coupons from "./AccountConfig/Coupons";
import Terms from "./Terms";
import {
  ConfigBody,
  ConfigMain,
  ConfigWrapper,
  MenuHeader,
  MenuOption,
  MobileHeader,
  MobileHeaderTitle,
  OptionText,
  RowLineOptions,
} from "./styles";

export function MobileHeaderComponent({ ...props }) {
  const { title } = props;
  return (
    <MobileHeader>
      <MobileHeaderTitle>{title}</MobileHeaderTitle>
    </MobileHeader>
  );
}

export function TopMenuBar({ ...props }) {
  const { options, setSelected, selected } = props;
  return (
    <MenuHeader>
      {options?.map(item => (
        <MenuOption
          active={selected === item}
          key={item}
          onClick={() => setSelected(item)}
          type="button"
        >
          <OptionText active={selected === item}>{item}</OptionText>
        </MenuOption>
      ))}
    </MenuHeader>
  );
}

function ConfigBodyComponent({ ...props }) {
  const {
    disabled,
    form,
    selected,
    setUpdateBank,
    updateBank,
    user,
    validateInfos,
  } = props;

  return (
    <ConfigBody>
      {selected === "Dados básicos" && (
        <UpdateBasicData
          form={form}
          user={user}
          validateInfos={validateInfos}
        />
      )}
      {selected === "Endereço comercial" && (
        <UpdateAdress form={form} user={user} validateInfos={validateInfos} />
      )}
      {selected === "Dados profissionais" && (
        <UpdateProfessionalInfos
          form={form}
          user={user}
          validateInfos={validateInfos}
        />
      )}
      {selected === "Dados bancários" && (
        <UpdateBankAccount
          form={form}
          updateBank={updateBank}
          setUpdateBank={setUpdateBank}
          validateInfos={validateInfos}
        />
      )}
      {selected === "Disponibilidade" && (
        <Availability config={true} form={form} />
      )}
      {selected === "Cupons" && <Coupons />}
      {selected === "Segurança" && (
        <UpdatePassword form={form} validateInfos={validateInfos} />
      )}
      {selected === "Termos de uso" && <Terms />}
      {!["Disponibilidade", "Cupons", "Termos de uso"].includes(selected) && (
        <ConfigWrapper style={{ marginTop: "3rem" }}>
          <StaticButton
            height="3rem"
            title="Salvar alterações"
            disabled={disabled}
          />
        </ConfigWrapper>
      )}
    </ConfigBody>
  );
}

export function ConfigScreen() {
  const history = useHistory();
  const {
    professionalUpdateAdress,
    professionalUpdateBasicData,
    professionalUpdateProfessionalInfos,
    setAuthData,
    updatePassword,
    updateProfessional,
    user,
  } = useAuth();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [selected, setSelected] = useState("Dados básicos");
  const [updateBank, setUpdateBank] = useState(false);

  const options = [
    "Dados básicos",
    "Endereço comercial",
    "Dados profissionais",
    "Dados bancários",
    "Disponibilidade",
    "Cupons",
    "Segurança",
    "Termos de uso",
  ];

  const professorPrice = 50; // Valor mínimo de consulta para professor
  const psychologistPrice = 80; // Valor mínimo de consulta para psicólogo
  const professionalAdvisorPrice = 70; // Valor mínimo de consulta para orientador profissional
  const psychopedagoguePrice = 70; // Valor mínimo de consulta para psicopedagogo
  const durationAppointment = 30; // Duração mínima de consulta em minutos

  const formatedDate = date => moment(date).format("DD/MM/YYYY");

  const schemaInfoPersonal = Yup.object({
    avatar: Yup.string().required("Foto de perfil é obrigatório!").nullable(),
    firstName: Yup.string().required("Nome é obrigatório!").nullable(),
    lastName: Yup.string().required("Sobrenome é obrigatório!").nullable(),
    birthday: Yup.string()
      .required("Data de nascimento é obrigatório!")
      .nullable(),
    phone: Yup.string().required("Telefone é obrigatório!").nullable(),
    documentNumber: Yup.string()
      .nullable()
      .test({
        name: "isValid",
        exclusive: false,
        params: {},
        message: "CPF inválido",
        test(value) {
          return validateCPF(value || "");
        },
      }),
  });

  const schemaBusinessAddress = Yup.object({
    cep: Yup.string().required("CEP é obrigatório!").nullable(),
    street: Yup.string().required("Endereço é obrigatório!").nullable(),
    street_number: Yup.string().required("Número é obrigatório!").nullable(),
    complement: Yup.string().optional().nullable(),
    neighborhood: Yup.string().required("Bairro é obrigatório!").nullable(),
    city: Yup.string().required("Cidade é obrigatório!").nullable(),
    state: Yup.string().required("Estado é obrigatório!").nullable(),
    country: Yup.string().required("Pais é obrigatório!").nullable(),
  });

  const schemaProfessional = Yup.object({
    specialities: Yup.array()
      .min(1, "Escolha no mínimo uma especialidade")
      .max(8, "Escolha no máximo oito especialidades")
      .required("Especialidades são obrigatórias")
      .nullable(),
    reasons: Yup.array()
      .min(1, "Escolha no mínimo um motivo")
      .max(8, "Escolha no máximo oito motivos")
      .required("Motivos são obrigatórias")
      .nullable(),
    approaches: Yup.array()
      .min(1, "Escolha no mínimo uma abordagem")
      .max(8, "Escolha no máximo oito abordagens")
      .required("Abordagens são obrigatórias")
      .nullable(),
    bio: Yup.string()
      .max(200, "Tamanho máximo de 200 caracteres")
      .required("Biografia é obrigatória!")
      .nullable(),
    ageGroups: Yup.array()
      .min(1, "Escolha no mínimo uma faixa etária")
      .required("Faixa etária é obrigatório")
      .nullable(),
    appointmentTypes: Yup.array()
      .min(1, "Escolha no mínimo um tipo de agendamento")
      .required("Tipo de agendamento é obrigatório")
      .nullable(),
    presentationVideo: Yup.string().nullable(),
    sessionPrice: Yup.number("Valor deve ser numérico")
      .nullable()
      .when("job", {
        is: job => job === "Psicólogo(a)",
        then: Yup.number("Valor deve ser numérico").min(
          psychologistPrice,
          "Valor da consulta não pode ser inferior a R$ 80,00",
        ),
      })
      .when("job", {
        is: job => job === "Professor(a)",
        then: Yup.number("Valor deve ser numérico").min(
          professorPrice,
          "Valor da consulta não pode ser inferior a R$ 50,00",
        ),
      })
      .when("job", {
        is: job => job === "Orientador(a) Profissional",
        then: Yup.number("Valor deve ser numérico").min(
          professionalAdvisorPrice,
          "Valor da consulta  não pode ser inferior a R$ 70,00",
        ),
      })
      .when("job", {
        is: job => job === "Psicopedagogo(a)",
        then: Yup.number("Valor deve ser numérico").min(
          psychopedagoguePrice,
          "Valor da consulta  não pode ser inferior a R$ 70,00",
        ),
      }),
    preferentialPayment: Yup.string()
      .required("Tipo de pagamento é obrigatório")
      .nullable(),
    newPreferOwnMeetingLink: Yup.boolean().optional(),
    ownMeetingLink: Yup.string().when("newPreferOwnMeetingLink", {
      is: true,
      then: Yup.string()
        .nullable()
        .url("Link de reunião deve ser uma URL válida")
        .required("Link de reunião é obrigatório"),
    }),
  });

  const schemaBank = Yup.object({
    bankCode: Yup.string().required("Banco é obrigatório").nullable(),
    bankAg: Yup.string().required("Agência da conta é obrigatório").nullable(),
    agenciaDv: Yup.string().optional().nullable(),
    bankCc: Yup.string().required("Número da conta é obrigatório").nullable(),
    contaDv: Yup.string().required("Dígito da conta é obrigatório").nullable(),
    accountType: Yup.string()
      .required("Tipo da conta é obrigatório")
      .nullable(),
    name: Yup.string().required("Nome é obrigatório").nullable(),
  });

  const schemaSecurity = Yup.object({
    password: Yup.string().required("Senha é obrigatório"),
    newPassword: Yup.string().required("Nova senha é obrigatório"),
    confirmPassword: Yup.string()
      .required("Confirmação de senha é obrigatório")
      .oneOf([Yup.ref("newPassword"), null], "Senhas não conferem"),
  });

  const schemaAvailability = Yup.object({
    breakTime: Yup.number()
      .min(0, "Dempo de descanço não pode ser menor que 0")
      .optional(),
    workdays: Yup.array()
      .min(1, "Selecione pelo menos um dia da semana")
      .required("Requerido"),
    appointmentTypes: Yup.array().required("Requerido"),
    appointmentDuration: Yup.number()
      .min(
        durationAppointment,
        `Duração de atendimento não pode ser menor que ${durationAppointment} minutos`,
      )
      .required("Requerido"),
  });

  const schemas = () => {
    switch (selected) {
      case "Dados básicos":
        return schemaInfoPersonal;
      case "Endereço comercial":
        return schemaBusinessAddress;
      case "Dados profissionais":
        return schemaProfessional;
      case "Dados bancários":
        return schemaBank;
      case "Disponibilidade":
        return schemaAvailability;
      case "Segurança":
        return schemaSecurity;
      default:
        return schemaInfoPersonal;
    }
  };

  const updateMethod = (values, id, method) => {
    switch (method) {
      case "Dados básicos":
        return professionalUpdateBasicData(values, id);
      case "Endereço comercial":
        return professionalUpdateAdress(values, id);
      case "Dados profissionais":
        return professionalUpdateProfessionalInfos(values, id);
      default:
        return updateProfessional(values, id);
    }
  };

  const submitForm = async values => {
    values.avatar === user?.avatar && delete values.avatar;
    try {
      setSubmitLoading(true);
      const success = await updateMethod(values, user.id, selected);

      if (success) {
        toast.success("Dados atualizados com sucesso.");
      } else {
        toast.error(
          "Erro no servidor. Reveja se seus dados estão corretos e tente se cadastrar novamente em alguns minutos.",
        );
      }
    } catch (error) {
      toast.error(
        "Erro no servidor. Reveja se seus dados estão corretos e tente se cadastrar novamente em alguns minutos.",
      );
    } finally {
      setSubmitLoading(false);
      history.push({
        pathname: "/professional/config",
        state: { selected: selected },
      });
    }
  };

  const submitFormBank = async values => {
    try {
      setSubmitLoading(true);
      const documentNumber = user?.documentNumber
        .split(".")
        .join("")
        .replace("-", "");
      const request = {
        data: {
          type: "payment_accounts",
          attributes: {
            documentNumber,
            name: values?.name,
            bankAg: values?.bankAg,
            accountType: values.accountType,
            bankCc: values?.bankCc,
            bankCode: values?.bankCode,
            agenciaDv: values?.agenciaDv,
            contaDv: values?.contaDv,
          },
        },
      };
      if (!values.agenciaDv) delete request.data.attributes.agenciaDv;
      let data = {};
      if (user?.paymentAccount?.id) {
        const { data: response } = await api.put(
          `/professionals/payment_accounts/${user?.paymentAccount?.id}`,
          request,
        );
        data = response;
      } else {
        const { data: response } = await api.post(
          "/professionals/payment_accounts",
          request,
        );
        data = response;
      }
      if (data.data) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...user,
            paymentAccount: data.data.attributes,
          }),
        );
        setAuthData({
          ...user,
          paymentAccount: data.data.attributes,
        });
        toast.success("Dados bancários atualizados com sucesso.");
      } else {
        throw new Error(JSON.stringify(data.error));
      }
    } catch (error) {
      toast.error("Falha ao atualizar conta bancária.");
    } finally {
      setSubmitLoading(false);
      history.push({
        pathname: "/professional/config",
        state: { selected: selected },
      });
    }
  };

  const submitFormSecurity = async values => {
    setSubmitLoading(true);
    try {
      const data = await updatePassword(values, user?.type, user?.id);
      if (data.error) {
        return toast.error("Senha inválida");
      }
      if (data) {
        return toast.success("Senha alterada com sucesso");
      }
    } catch (error) {
      toast.error("Erro ao alterar senha");
    } finally {
      setSubmitLoading(false);
      history.push({
        pathname: "/professional/config",
        state: { selected: selected },
      });
    }
  };

  const submitFormAvailability = async values => {
    try {
      setSubmitLoading(true);
      const success = await updateProfessional(values, user?.id);
      if (success) {
        history.push("/professional/dashboard");
        toast.success("Disponibilidade Atualizada!");
      } else {
        alert(
          "Erro no servidor. Reveja se seus dados estão corretos e tente se cadastrar novamente em alguns minutos.",
        );
      }
    } catch (error) {
      toast.error("Erro ao atualizar disponibilidade");
    } finally {
      setSubmitLoading(false);
      history.push({
        pathname: "/professional/config",
        state: { selected: "Disponibilidade" },
      });
    }
  };

  const form = useFormik({
    initialValues: {
      // Dados Pessoais
      avatar: user?.avatar || "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      hasSocialName: !user?.socialName === "" ? true : false,
      socialName: user?.socialName || "",
      phone: user?.phone || "",
      birthday: formatedDate(user?.birthday) || "",
      documentNumber: user?.documentNumber || "",
      termId: user?.term?.id || "",

      // Dados de Endereço
      cep: user?.address.cep || "",
      street: user?.address.street || "",
      neighborhood: user?.address.neighborhood || "",
      street_number: user?.address.street_number || "",
      complement: user?.address.complement || "N/A",
      country: user?.address.country || "",
      state: user?.address.state || "",
      city: user?.address.city || "",

      // Dados Profissionais
      professionalDocumentNumber: user?.professionalDocumentNumber || "",
      academicBackground: user?.academicBackground || [""],
      specialities: user?.specialities || [],
      approaches: user?.approaches || [],
      reasons: user?.reasons || [],
      sessionPrice: user?.sessionPrice || 0,
      bio: user?.bio || "",
      presentationVideo: user?.presentationVideo || "",
      ageGroups: user?.ageGroups || [],
      yearsOfExperience: user?.yearsOfExperience || 0,
      newPreferOwnMeetingLink: user?.ownMeetingLink ? true : false,

      // Dados Bancários e Pagamentos
      accountType: user?.paymentAccount?.accountType || "",
      agenciaDv: user?.paymentAccount?.agenciaDv || "",
      bankAg: user?.paymentAccount?.bankAg || "",
      bankCc: user?.paymentAccount?.bankCc || "",
      bankCode: user?.paymentAccount?.bankCode || "",
      contaDv: user?.paymentAccount?.contaDv || "",
      name: user?.paymentAccount?.name || "",
      preferentialPayment: user?.preferentialPayment || "",

      // Dados de disponibilidade
      appointmentDuration: user?.appointmentDuration || durationAppointment,
      appointmentTypes: user?.availabilityRule.appointmentTypes || [],
      breakTime: user?.availabilityRule?.breakTime || 0,
      workdays: user?.availabilityRule?.workdays || [],
      appointmentTimeBlocks:
        user?.availabilityRule?.appointmentTimeBlocks || [],
      blockedTimes: user?.availabilityRule?.blockedTimes || [],
    },
    validationSchema: schemas(),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      if (selected === "Dados bancários") submitFormBank(values);
      else if (selected === "Segurança") submitFormSecurity(values);
      else if (selected === "Disponibilidade") submitFormAvailability(values);
      else submitForm(values);
    },
  });

  const validateInfos = values => {
    setDisabled(values);
  };

  useEffect(() => {
    const controller = new AbortController();
    const getUserAvailabilityRule = async () => {
      try {
        setSubmitLoading(true);
        const { data } = await api.get("/professionals/availabilities/rule", {
          signal: controller.signal,
        });
        form.setFieldValue(
          "blockedTimes",
          data?.data?.attributes?.blockedTimes || [],
        );
        form.setFieldValue(
          "appointmentDuration",
          data?.data?.attributes?.appointmentDuration,
        );
        form.setFieldValue("breakTime", data?.data?.attributes?.breakTime);
        form.setFieldValue(
          "workdays",
          getSortedWorkdays(data?.data?.attributes?.workdays),
        );
        form.setFieldValue(
          "appointmentTypes",
          data?.data?.attributes?.appointmentTypes,
        );
        form.setFieldValue(
          "appointmentTimeBlocks",
          data?.data?.attributes?.appointmentTimeBlocks,
        );
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error("Erro ao buscar as suas regras de disponibilidade");
      } finally {
        setSubmitLoading(false);
      }
    };
    getUserAvailabilityRule();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    const verifyHistoryState = () => {
      const selectedCash = history.location?.state?.selected;
      if (selectedCash) {
        setSelected(selectedCash);
      }
    };
    verifyHistoryState();
  }, []);

  return (
    <FormikProvider value={form}>
      <ConfigMain onSubmit={form.handleSubmit}>
        <TopMenuBar
          options={options}
          setSelected={setSelected}
          selected={selected}
        />
        <RowLineOptions />
        {submitLoading ? (
          <LoaderSpinner />
        ) : (
          <ConfigBodyComponent
            disabled={disabled}
            form={form}
            selected={selected}
            setUpdateBank={setUpdateBank}
            updateBank={updateBank}
            user={user}
            validateInfos={validateInfos}
          />
        )}
      </ConfigMain>
    </FormikProvider>
  );
}
