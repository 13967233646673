import React, { Fragment, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation } from "react-router-dom";
import TermClinic from "../../assets/contrato_clinicas_allminds_2024.pdf";
import TermProfessional from "../../assets/contrato_profissional_allminds_2024.pdf";
import { StyledPage } from "./styles";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default function AllPages() {
  const location = useLocation();
  const pdfUrl = location.pathname.includes("clinica")
    ? TermClinic
    : TermProfessional;
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <StyledPage>
      <Document
        file={pdfUrl}
        onLoadError={console.error}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Fragment key={`page_${index + 1}`}>
            <Page
              size="A4"
              key={`page_${index + 1}`}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              pageNumber={index + 1}
              height={window.innerHeight}
              width={window.innerWidth}
            />
            <p
              style={{
                fontFamily: "Poppins",
                fontSize: "1rem",
                fontWeight: "bold",
                textAlign: "right",
                width: "90%",
              }}
            >
              Página {index + 1} de {numPages}
            </p>
          </Fragment>
        ))}
      </Document>
    </StyledPage>
  );
}
