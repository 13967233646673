/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { StaticButton } from "../../../../components/Button";
import FormikControl from "../../../../components/FormikControl";
import { Input } from "../../../../components/FormikElements/styles";
import { ImageCropper } from "../../../../components/ImageInput";
import { FixedTextInput, NewCheckBoxInput } from "../../../../components/Input";
import LoaderSpinner from "../../../../components/LoaderSpinner";
import { NewSelect } from "../../../../components/SelectList";
import useAuth from "../../../../hooks/auth";
import api, { brasilApi, viaCepApi } from "../../../../services/api";
import { PtBr } from "../../../../services/pt_br";
import theme from "../../../../theme";
import getProfessionName from "../../../../utils/getProfessionName";
import { getProfessions } from "../../../../utils/getProfessionsFilter";
import { getPreferencialPayments } from "../../../../utils/preferencialPayments";
import { addLeftZeros } from "../../BankAccount";
import {
  ConfigButtonContainer,
  ConfigDataForm,
  ConfigWrapper,
  ContainerAccCofig,
  ContainerImage,
  ContainerPhoneBirth,
  ContainerTitleBank,
  ContentContainer,
  ContentLabel,
  RowLine,
  RowWrapper,
  TextError,
  Titleh1,
} from "../styles";

export default function AccountConfig({ ...props }) {
  const { loading, setLoading } = props;
  const { user } = useAuth();
  const [icon, setIcon] = useState(<ContentCopyRoundedIcon />);
  const [copyLabel, setCopyLabel] = useState("Clique para Copiar");
  const history = useHistory();
  const referralLink = `${window.location.host}/ref=${user?.email}`;

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const handleClick = async () => {
    setIcon(<CheckRoundedIcon />);
    setCopyLabel("Copiado");

    await delay(10000);

    setIcon(<ContentCopyRoundedIcon />);
    setCopyLabel("Clique para Copiar");
  };

  return (
    <Fragment>
      {loading ? (
        <LoaderSpinner setLoading={setLoading} />
      ) : (
        <Fragment>
          <ContentContainer>
            <ContentLabel>Configurações pessoais</ContentLabel>
            <ConfigButtonContainer>
              <StaticButton
                disabled
                title="Dados básicos"
                onClick={() =>
                  history.push({
                    pathname: "/professional/availability",
                  })
                }
              />
            </ConfigButtonContainer>
            <ConfigButtonContainer>
              <StaticButton
                title="Conta Bancaria"
                onClick={() =>
                  history.push({
                    pathname: "/professional/payment_account",
                  })
                }
              />
            </ConfigButtonContainer>
          </ContentContainer>
          <ContentContainer>
            <ContentLabel>Configurações de atendimento</ContentLabel>
            <ConfigButtonContainer>
              <StaticButton
                disabled
                title="Endereço comercial"
                onClick={() =>
                  history.push({
                    pathname: "/professional/availability",
                  })
                }
              />
            </ConfigButtonContainer>
            <ConfigButtonContainer>
              <StaticButton
                title="Configurações de atendimento"
                onClick={() =>
                  history.push({
                    pathname: "/professional/availability",
                    state: { firstStep: 3 },
                  })
                }
              />
            </ConfigButtonContainer>
            <ConfigButtonContainer>
              <StaticButton
                title="Cupons"
                onClick={() =>
                  history.push({
                    pathname: "/professional/cupons",
                  })
                }
              />
            </ConfigButtonContainer>
          </ContentContainer>
          <ContentContainer>
            <ContentLabel> Link de convite para a plataforma </ContentLabel>
            <ConfigButtonContainer>
              <Button
                variant="outlined"
                sx={{ width: "100%", height: "100%", fontSize: "medium" }}
                endIcon={icon}
                onClick={() => {
                  handleClick();
                  navigator.clipboard.writeText(referralLink);
                }}
              >
                {copyLabel}
              </Button>
            </ConfigButtonContainer>
          </ContentContainer>
        </Fragment>
      )}
    </Fragment>
  );
}

export function UpdateBasicData({
  form,
  user,
  validateInfos,
  validateNeedLegalGuardian,
}) {
  const { errors, handleBlur, handleChange, touched, values, setFieldValue } =
    form;
  const [validateForm, setValidateForm] = useState(true);
  const [firstStep, setFirstStep] = useState(true);

  const formatedDate = date => moment(date).format("DD/MM/YYYY");

  useEffect(() => {
    const validateInfo =
      user?.avatar === values?.avatar &&
      user?.firstName === values?.firstName &&
      user?.lastName === values?.lastName &&
      formatedDate(user?.birthday) === values?.birthday &&
      user?.documentNumber === values?.documentNumber &&
      user?.phone === values?.phone;
    const validateErrors =
      errors?.firstName ||
      errors?.lastName ||
      errors?.birthday ||
      errors?.phone ||
      errors?.documentNumber;
    setValidateForm(validateErrors || validateInfo);

    validateInfos(firstStep || validateErrors || validateInfo);
  }, [values, errors]);

  useEffect(() => {
    const validateStep = [
      values?.firstName,
      values?.lastName,
      values?.birthday,
      values?.phone,
    ]?.every(value => value === "");
    setFirstStep(validateStep);
  }, [validateForm]);

  return (
    <ConfigWrapper>
      <div style={{ padding: "0 10px 20px 0" }}>
        <Titleh1>Informações Pessoais</Titleh1>
        <RowLine />
      </div>
      <ContainerImage>
        <ImageCropper
          src={values?.avatar}
          name="avatar"
          acceptType=".jpeg, .jpg"
          onCrop={crop => setFieldValue("avatar", crop)}
        />

        <TextError>{errors?.avatar}</TextError>
      </ContainerImage>

      <FormikControl
        control="input"
        errors={errors?.firstName}
        label="Nome"
        name="firstName"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.firstName}
        type="text"
        upLabel={true}
        value={values?.firstName}
      />

      <FormikControl
        control="input"
        errors={errors?.lastName}
        label="Sobrenome"
        name="lastName"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.lastName}
        type="text"
        upLabel={true}
        value={values?.lastName}
      />

      <ContainerPhoneBirth>
        <FormikControl
          control="input"
          label="Telefone"
          mask="(99) 99999-9999"
          name="phone"
          onChange={handleChange}
          type="text"
          upLabel={true}
          value={values?.phone}
          errors={errors?.phone}
          onBlur={handleBlur}
          touched={touched?.phone}
        />

        <FormikControl
          control="input"
          disabled={user?.needLegalGuardian}
          errors={
            errors?.birthday ||
            (!errors?.birthday &&
              validateNeedLegalGuardian === true &&
              moment(values?.birthday, "DD/MM/YYYY").isAfter(
                moment().subtract(18, "years"),
              ) &&
              "Você precisara cadastrar um responsável legal")
          }
          label="Data de nascimento"
          mask="99/99/9999"
          name="birthday"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched?.birthday}
          type="text"
          upLabel={true}
          value={values?.birthday}
        />
      </ContainerPhoneBirth>

      <FormikControl
        control="input"
        disabled={user?.documentNumber}
        errors={errors?.documentNumber}
        label="CPF"
        mask="999.999.999-99"
        name="documentNumber"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.documentNumber}
        type="text"
        upLabel={true}
        value={values?.documentNumber || user?.documentNumber}
      />
    </ConfigWrapper>
  );
}

export function UpdateAdress({ form, user, validateInfos }) {
  const { errors, handleBlur, handleChange, setFieldValue, touched, values } =
    form;
  const [validateForm, setValidateForm] = useState(true);
  const [firstStep, setFirstStep] = useState(true);
  const [cep, setCep] = useState("");
  const onlyNumbers = str => str.replace(/[_]/g, "");

  useEffect(() => {
    const controller = new AbortController();
    const handleCEP = async () => {
      if (onlyNumbers(cep)?.length === 9) {
        try {
          const { data } = await viaCepApi.get(`${values?.cep}/json/`, {
            signal: controller.signal,
          });
          if (data) {
            setFieldValue("cep", onlyNumbers(cep));
            setFieldValue("street", data.logradouro);
            setFieldValue("complement", "N/A");
            setFieldValue("neighborhood", data.bairro);
            setFieldValue("city", data.localidade);
            setFieldValue("state", data.uf);
            setFieldValue("country", "Brasil");
          }
        } catch (error) {
          if (!controller.signal.aborted) toast.error("CEP inválido");
        }
      }
    };
    handleCEP();

    return () => controller.abort();
  }, [cep]);

  const validateComplement = () => {
    if (values?.complement === "") {
      setFieldValue("complement", "N/A");
    }
  };

  useEffect(() => {
    const validateInfo =
      user?.address.street === values?.street &&
      user?.address.street_number === values?.street_number &&
      user?.address.complement === values?.complement &&
      user?.address.neighborhood === values?.neighborhood &&
      user?.address.cep === values?.cep &&
      user?.address.country === values?.country &&
      user?.address.state === values?.state &&
      user?.address.city === values?.city;
    const validateErrors = [
      errors?.street,
      errors?.street_number,
      errors?.complement,
      errors?.neighborhood,
      errors?.cep,
      errors?.country,
      errors?.state,
      errors?.city,
    ]?.some(error => error !== undefined);
    setValidateForm(validateErrors || validateInfo);
    validateInfos(firstStep || validateErrors || validateInfo);
  }, [values, errors]);

  useEffect(() => {
    const validateStep = [
      values?.street,
      values?.street_number,
      values?.complement,
      values?.neighborhood,
      values?.cep,
      values?.country,
      values?.state,
      values?.city,
    ]?.every(value => value === "");
    setFirstStep(validateStep);
  }, [validateForm]);

  return (
    <ConfigWrapper>
      <div style={{ padding: "0 10px 20px 0" }}>
        <Titleh1>
          {user?.type === "client" ? "Endereço" : "Endereço Comercial"}
        </Titleh1>
        <RowLine />
      </div>

      <FormikControl
        control="input"
        errors={errors?.cep}
        label="CEP"
        mask="99999-999"
        name="cep"
        onBlur={handleBlur}
        onChange={handleChange}
        onInput={({ target }) => setCep(target?.value)}
        touched={touched?.cep}
        type="text"
        upLabel={true}
        value={values?.cep}
      />

      <FormikControl
        control="input"
        errors={errors?.street}
        label="Endereço"
        name="street"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.street}
        type="text"
        upLabel={true}
        value={values?.street}
      />

      <FormikControl
        control="input"
        errors={errors?.street_number}
        label="Número"
        name="street_number"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.street_number}
        type="text"
        upLabel={true}
        value={values?.street_number}
      />

      <FormikControl
        control="input"
        errors={errors?.neighborhood}
        label="Bairro"
        name="neighborhood"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.neighborhood}
        type="text"
        upLabel={true}
        value={values?.neighborhood}
      />

      <FormikControl
        control="input"
        errors={errors?.complement}
        label={PtBr.register_input_complement}
        name="complement"
        onBlur={e => {
          handleBlur(e);
          validateComplement();
        }}
        onChange={handleChange}
        touched={touched?.complement}
        upLabel={true}
        value={values?.complement}
      />

      <FormikControl
        control="input"
        errors={errors?.city}
        label="Cidade"
        name="city"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.city}
        type="text"
        upLabel={true}
        value={values?.city}
      />

      <FormikControl
        control="input"
        errors={errors?.state}
        label="Estado"
        name="state"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.state}
        type="text"
        upLabel={true}
        value={values?.state}
      />

      <FormikControl
        control="input"
        errors={errors?.country}
        label="País"
        name="country"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.country}
        type="text"
        upLabel={true}
        value={values?.country}
      />
    </ConfigWrapper>
  );
}

export function UpdateLegalGuardian({ form, validateInfos, user }) {
  const { handleChange, handleBlur, values, errors, touched } = form;
  const [validateForm, setValidateForm] = useState(true);
  const [firstStep, setFirstStep] = useState(true);

  useEffect(() => {
    const validateInfo =
      user?.legalGuardianFullName === values?.legalGuardianFullName &&
      user?.legalGuardianEmail === values?.legalGuardianEmail &&
      user?.legalGuardianDocumentNumber ===
        values?.legalGuardianDocumentNumber &&
      user?.legalGuardianBirthday === values?.legalGuardianBirthday &&
      user?.legalGuardianPhone === values?.legalGuardianPhone;
    const validateErrors =
      errors?.legalGuardianFullName ||
      errors?.legalGuardianEmail ||
      errors?.legalGuardianDocumentNumber ||
      errors?.legalGuardianBirthday ||
      errors?.legalGuardianPhone;
    setValidateForm(validateErrors || validateInfo);

    validateInfos(firstStep || validateErrors || validateInfo);
  }, [values, errors]);

  useEffect(() => {
    const validateStep = [
      values?.legalGuardianFullName,
      values?.legalGuardianEmail,
      values?.legalGuardianDocumentNumber,
      values?.legalGuardianBirthday,
      values?.legalGuardianPhone,
    ]?.every(value => value === "");
    setFirstStep(validateStep);
  }, [validateForm]);

  return (
    <ConfigWrapper>
      <div style={{ padding: "0 10px 20px 0" }}>
        <Titleh1>Responsável Legal</Titleh1>
        <RowLine />
      </div>

      <FormikControl
        control="input"
        label="Nome completo do responsável"
        name="legalGuardianFullName"
        onChange={handleChange}
        type="text"
        upLabel={true}
        value={values?.legalGuardianFullName}
        errors={errors?.legalGuardianFullName}
        onBlur={handleBlur}
        touched={touched?.legalGuardianFullName}
      />

      <FormikControl
        control="input"
        errors={errors?.legalGuardianEmail}
        label="Email do responsável"
        name="legalGuardianEmail"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.legalGuardianEmail}
        type="email"
        upLabel={true}
        value={values?.legalGuardianEmail}
      />

      <FormikControl
        control="input"
        errors={errors?.legalGuardianDocumentNumber}
        label="CPF do responsável"
        name="legalGuardianDocumentNumber"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.legalGuardianDocumentNumber}
        type="text"
        upLabel={true}
        value={values?.legalGuardianDocumentNumber}
      />

      <FormikControl
        control="input"
        errors={errors?.legalGuardianBirthday}
        label="Data de nascimento do responsável"
        mask="99/99/9999"
        name="legalGuardianBirthday"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.legalGuardianBirthday}
        type="text"
        upLabel={true}
        value={values?.legalGuardianBirthday}
      />

      <FormikControl
        control="input"
        errors={errors?.legalGuardianPhone}
        label="Telefone do responsável"
        mask="(99) 99999-9999"
        name="legalGuardianPhone"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched?.legalGuardianPhone}
        type="text"
        upLabel={true}
        value={values?.legalGuardianPhone}
      />
    </ConfigWrapper>
  );
}

export function UpdateBankAccount({
  form,
  updateBank,
  setUpdateBank,
  validateInfos,
  clinic,
}) {
  const { handleChange, handleBlur, values, setFieldValue, errors, touched } =
    form;
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [bankList, setBankList] = useState([]);
  const [bank, setBank] = useState(null);

  useEffect(() => {
    const fetchBankCodes = async () => {
      try {
        await brasilApi
          .get()
          .then(({ data, errors }) => {
            if (errors) {
              throw new Error("Erro ao carregar bancos");
            }
            setBankList(
              data?.map(bank => ({
                label: bank.fullName,
                value: { code: bank.code, fullName: bank.fullName },
              })),
            );

            const bankFullName = data?.filter(
              bank => addLeftZeros(bank.code, 3) === values?.bankCode,
            )[0]?.fullName;
            setBank({
              label: bankFullName,
              value: bankFullName,
            });
          })
          .catch(error => toast.error(error));
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchBankCodes();
  }, []);

  useEffect(() => {
    const validateInfo =
      user?.paymentAccount?.agenciaDv === values?.agenciaDv &&
      user?.paymentAccount?.bankAg === values?.bankAg &&
      user?.paymentAccount?.bankCc === values?.bankCc &&
      user?.paymentAccount?.bankCode === values?.bankCode &&
      user?.paymentAccount?.contaDv === values?.contaDv;
    validateInfos(validateInfo);
  }, [values]);

  useEffect(() => {
    setFieldValue("agenciaDv", user?.paymentAccount?.agenciaDv);
    setFieldValue("bankAg", user?.paymentAccount?.bankAg);
    setFieldValue("bankCc", user?.paymentAccount?.bankCc);
    setFieldValue("bankCode", user?.paymentAccount?.bankCode);
    setFieldValue("contaDv", user?.paymentAccount?.contaDv);
  }, [user]);

  return (
    <ConfigWrapper>
      <div style={{ padding: "0 10px 20px 0" }}>
        <Titleh1>Dados Bancários</Titleh1>
        <RowLine />
      </div>

      <ContainerTitleBank>
        <NewCheckBoxInput
          label="Atualizar dados bancários"
          checked={updateBank}
          onChange={() => setUpdateBank(!updateBank)}
        />
      </ContainerTitleBank>

      {loading ? (
        <p>Carregando dados bancários...</p>
      ) : (
        <Fragment>
          <NewSelect
            defaultValue={bank?.value}
            errors={errors.bankCode}
            isDisabled={!updateBank}
            label="Banco"
            name="bankName"
            onChange={e => {
              const { value } = e;
              const code = bankList?.filter(
                bank => bank.value.fullName === value,
              )[0]?.value?.code;
              setFieldValue("bankCode", addLeftZeros(code, 3));
              setBank(e);
            }}
            options={bankList?.map(bank => {
              return { label: bank.label, value: bank.value.fullName };
            })}
            placeholder="Selecione o banco"
            touched={touched.bankCode}
          />

          <RowWrapper>
            <FormikControl
              disabled={!updateBank}
              name="bankAg"
              label="Agência"
              upLabel={true}
              type="text"
              control="input"
              value={values?.bankAg}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors?.bankAg}
              touched={touched?.bankAg}
            />

            <FormikControl
              control="input"
              disabled={!updateBank}
              errors={errors?.agenciaDv}
              label="Dígito"
              name="agenciaDv"
              onBlur={handleBlur}
              onChange={handleChange}
              touched={touched?.agenciaDv}
              type="text"
              upLabel={true}
              value={values?.agenciaDv}
            />
          </RowWrapper>

          <RowWrapper>
            <FormikControl
              control="input"
              disabled={!updateBank}
              errors={errors?.bankCc}
              label="Conta"
              name="bankCc"
              onBlur={handleBlur}
              onChange={handleChange}
              touched={touched?.bankCc}
              type="text"
              upLabel={true}
              value={values?.bankCc}
            />

            <FormikControl
              control="input"
              disabled={!updateBank}
              errors={errors?.contaDv}
              label="Dígito"
              name="contaDv"
              onBlur={handleBlur}
              onChange={handleChange}
              touched={touched?.contaDv}
              type="text"
              upLabel={true}
              value={values?.contaDv}
            />
          </RowWrapper>

          <NewSelect
            placeholder="Selecione o tipo de conta"
            isDisabled={!updateBank}
            label="Tipo de conta"
            name="accountType"
            defaultValue={values?.accountType}
            onChange={e => {
              setFieldValue("accountType", e.value);
            }}
            options={[
              {
                label: "Conta Corrente",
                value: "conta_corrente",
              },
              {
                label: "Conta Poupança",
                value: "conta_poupança",
              },
            ]}
          />
          <TextError>{touched?.accountType && errors?.accountType}</TextError>

          <FormikControl
            name={clinic ? "paymentName" : "name"}
            label="Nome completo do titular da conta"
            upLabel={true}
            type="text"
            control="input"
            disabled={!updateBank}
            value={clinic ? values?.paymentName : values?.name}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={clinic ? errors?.paymentName : errors?.name}
            touched={clinic ? touched?.paymentName : touched?.name}
          />
        </Fragment>
      )}
    </ConfigWrapper>
  );
}

export function UpdateProfessionalInfos({ form, user, validateInfos }) {
  const { setFieldValue, handleChange, handleBlur, values, errors, touched } =
    form;
  const [specialities, setSpecialities] = useState([]);
  const [approaches, setApproaches] = useState([]);
  const [reason, setReasons] = useState([]);
  const groups = [
    { name: "Infantil" },
    { name: "Adulto" },
    { name: "Adolescente" },
    { name: "Idoso" },
  ];
  const appointmentTypes = [
    { value: "in_person", name: "Presencial" },
    { value: "online", name: "Online" },
  ];

  useEffect(() => {
    const validateInfo =
      JSON.stringify(user?.specialities) ===
        JSON.stringify(values?.specialities) &&
      JSON.stringify(user?.approaches) === JSON.stringify(values?.approaches) &&
      JSON.stringify(user?.reasons) === JSON.stringify(values?.reasons) &&
      user?.sessionPrice === values?.sessionPrice &&
      user?.preferentialPayment === values?.preferentialPayment &&
      user?.bio === values?.bio &&
      user?.presentationVideo === values?.presentationVideo &&
      JSON.stringify(user?.ageGroups) === JSON.stringify(values?.ageGroups) &&
      user?.yearsOfExperience === values?.yearsOfExperience &&
      user?.newPreferOwnMeetingLink === values?.newPreferOwnMeetingLink &&
      user?.ownMeetingLink === values?.ownMeetingLink;
    validateInfos(validateInfo);
  }, [errors, values]);

  useEffect(() => {
    const controller = new AbortController();
    const getSpecialities = async () => {
      try {
        const { data } = await api.get("/professions", {
          signal: controller.signal,
        });
        if (data) {
          const profession = getProfessions(
            data?.data?.attributes?.professions,
          );
          const userProfession = profession?.find(
            professions => professions.name === user?.profession,
          );
          setFieldValue("job", getProfessionName(user?.profession, "toPt"));
          setSpecialities(userProfession?.specialities);
          setApproaches(userProfession?.approaches);
          setReasons(userProfession?.reasons);
        }
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error("Erro ao carregar especialidades");
      }
    };
    getSpecialities();
    setFieldValue(
      "newPreferOwnMeetingLink",
      user?.ownMeetingLink ? true : false,
    );
    setFieldValue("ownMeetingLink", user?.ownMeetingLink);

    return () => controller.abort();
  }, []);

  const handleChangeCurrency = (_event, value) => {
    setFieldValue("sessionPrice", value);
  };

  const getPaymentsAcceptTypes = (acceptPix, acceptCredit) => {
    const preferencialPayments = [];
    if (acceptPix && acceptCredit) preferencialPayments.push("all_payment");
    else if (acceptPix) preferencialPayments.push("pix");
    else if (acceptCredit) preferencialPayments.push("credit_card");
    return preferencialPayments;
  };

  const preferentialPaymentTypes = user?.clinic
    ? getPaymentsAcceptTypes(
        user?.clinic?.acceptPix,
        user?.clinic?.acceptCredit,
      )
    : ["pix", "credit_card", "all_payment"];

  return (
    <ConfigWrapper>
      <ContainerAccCofig>
        <Titleh1>Informações Profissionais</Titleh1>
        <RowLine />

        {user?.subscription?.pagarmePlan === "platinum" && (
          <div>
            <Titleh1>Link Personalizado</Titleh1>

            <FormControlLabel
              name="secondaryBanner"
              control={<Checkbox checked={values.newPreferOwnMeetingLink} />}
              label={
                <Tooltip title="Cadastre um link de uma sala de reunião de sua preferencia para utilizar nas chamdas de video. Exemplo: https://meet.google.com/ppp-nnnn-vvv">
                  <Typography>
                    Adicionar Link Personalizado para{" "}
                    <span
                      style={{
                        color: theme.pink,
                        fontWeight: "bold",
                        marginLeft: "0.5rem",
                      }}
                    >
                      ?
                    </span>
                  </Typography>
                </Tooltip>
              }
              onChange={({ target }) =>
                setFieldValue("newPreferOwnMeetingLink", target.checked)
              }
            />

            {values.newPreferOwnMeetingLink && (
              <FormikControl
                control="input"
                errors={errors?.ownMeetingLink}
                label="Link da sala de reunião"
                name="ownMeetingLink"
                touched={touched?.ownMeetingLink}
                type="text"
                handleBlur={handleBlur}
                onChange={handleChange}
                upLabel={true}
                value={values?.ownMeetingLink}
              />
            )}
          </div>
        )}

        <RowLine />

        <FormikControl
          control="input"
          disabled={true}
          errors={errors?.professionalDocumentNumber}
          label="N de registro profissional"
          name="professionalDocumentNumber"
          touched={touched?.professionalDocumentNumber}
          type="text"
          upLabel={true}
          value={user?.professionalDocumentNumber}
        />

        <FormikControl
          control="input"
          disabled={true}
          label="Carreira profissional"
          name="profession"
          type="text"
          upLabel={true}
          value={getProfessionName(user?.profession, "toPt")}
        />

        <NewSelect
          placeholder="Selecione as especialidades"
          label="Especialidades"
          name="specialities"
          isMulti={true}
          defaultValue={values?.specialities?.map(spc => ({
            label: spc,
            value: spc,
          }))}
          onChange={e =>
            setFieldValue(
              "specialities",
              e?.map(spc => `${spc.value}`),
            )
          }
          options={specialities?.map(spc => ({ label: spc, value: spc }))}
        />
        <TextError>{errors?.specialities}</TextError>

        <NewSelect
          placeholder="Selecione as abordagens"
          label="Abordagens"
          name="approaches"
          isMulti={true}
          defaultValue={values?.approaches?.map(apr => ({
            label: apr,
            value: apr,
          }))}
          onChange={e => {
            setFieldValue(
              "approaches",
              e?.map(apr => `${apr.value}`),
            );
          }}
          options={approaches?.map(apr => ({ label: apr, value: apr }))}
        />
        <TextError>{errors?.approaches}</TextError>

        <NewSelect
          placeholder="Selecione os motivos"
          label="Motivos"
          name="reasons"
          isMulti={true}
          defaultValue={values?.reasons?.map(rea => ({
            label: rea,
            value: rea,
          }))}
          onChange={e => {
            setFieldValue(
              "reasons",
              e?.map(rea => rea.value),
            );
          }}
          options={reason?.map(rea => ({ label: rea, value: rea }))}
        />
        <TextError>{errors?.reasons}</TextError>

        <FormikControl
          upLabel
          control="input-currency"
          errors={errors?.sessionPrice}
          id="sessionPrice"
          label="Valor da sessão"
          name="sessionPrice"
          onChange={handleChangeCurrency}
          style={{ backgroundColor: theme.newPrimaryAlt }}
          touched={touched?.sessionPrice}
          value={Number(values?.sessionPrice)}
        />

        <NewSelect
          placeholder="Selecione a forma de recebimento"
          label="Tipos de recebimentos"
          name="preferentialPayment"
          defaultValue={values?.preferentialPayment}
          onChange={({ value }) => {
            setFieldValue("preferentialPayment", value);
          }}
          options={preferentialPaymentTypes?.map(type => {
            return { label: getPreferencialPayments(type), value: type };
          })}
        />
        <TextError>{errors?.preferentialPayment}</TextError>

        <FormikControl
          height="160px"
          name="bio"
          label="Mini Bio"
          upLabel={true}
          type="text"
          control="textarea"
          rows={5}
          value={values?.bio}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextError>{errors?.bio && touched?.bio && errors?.bio}</TextError>

        <Input
          type="url"
          name="presentationVideo"
          value={values?.presentationVideo}
          onChange={handleChange}
          onBlur={handleBlur}
          label={PtBr.register_input_presetation_video}
          placeholder={"https://www.youtube.com/"}
        />

        <TextError>
          {errors?.presentationVideo &&
            touched?.presentationVideo &&
            errors?.presentationVideo}
        </TextError>

        <NewSelect
          placeholder={PtBr.register_input_age_group}
          label={PtBr.register_input_age_group}
          name="ageGroups"
          isMulti={true}
          defaultValue={values?.ageGroups?.map(age => ({
            label: age,
            value: age,
          }))}
          onChange={e => {
            setFieldValue(
              "ageGroups",
              e?.map(age => `${age.value}`),
            );
          }}
          options={groups?.map(item => ({
            label: item.name,
            value: item.name,
          }))}
        />
        <TextError>{errors?.ageGroups}</TextError>

        <NewSelect
          placeholder={PtBr.register_input_appointmentTypes}
          label={PtBr.register_input_appointmentTypes}
          name="appointmentTypes"
          isMulti={true}
          defaultValue={values?.appointmentTypes?.map(apr => ({
            label: apr === "in_person" ? "Presencial" : "Online",
            value: apr,
          }))}
          onChange={e => {
            setFieldValue(
              "appointmentTypes",
              e?.map(apr => `${apr.value}`),
            );
          }}
          options={appointmentTypes?.map(item => ({
            label: item.name,
            value: item.value,
          }))}
        />
        <TextError>{errors?.appointmentTypes}</TextError>

        <FixedTextInput
          type="number"
          name="yearsOfExperience"
          value={values?.yearsOfExperience}
          onChange={handleChange}
          onBlur={handleBlur}
          label={PtBr.register_input_experience_time}
          min={0}
        />
        <TextError>
          {errors?.yearsOfExperience &&
            touched?.yearsOfExperience &&
            errors?.yearsOfExperience}
        </TextError>
      </ContainerAccCofig>
    </ConfigWrapper>
  );
}

export function UpdatePassword({ form, validateInfos }) {
  const { errors, handleBlur, handleChange, handleSubmit, touched, values } =
    form;

  const handleErrors = (errors, values) => {
    let pageHasErrors = false;
    if (values?.length > 0) {
      values.forEach(value => {
        if (errors[`${value}`]) {
          pageHasErrors = true;
        }
      });
    } else {
      Object.keys(errors).forEach(() => {
        pageHasErrors = true;
      });
    }
    return pageHasErrors;
  };

  const validatePassword = [
    values?.password,
    values?.newPassword,
    values?.confirmPassword,
  ].includes("");

  useEffect(() => {
    validateInfos(validatePassword || handleErrors(errors, values));
  }, [errors, values]);

  return (
    <ConfigWrapper>
      <ConfigDataForm onSubmit={handleSubmit}>
        <div style={{ padding: "0 10px 20px 0" }}>
          <Titleh1>Alterar minha senha</Titleh1>
          <RowLine />
        </div>
        <FormikControl
          control="input"
          label="Senha atual"
          name="password"
          onBlur={handleBlur}
          onChange={handleChange}
          type="password"
          upLabel={true}
          value={values?.password}
        />
        <TextError>
          {errors?.password && touched?.password && errors?.password}
        </TextError>
        <RowLine />
        <FormikControl
          control="input"
          label="Nova senha"
          name="newPassword"
          onBlur={handleBlur}
          onChange={handleChange}
          type="password"
          upLabel={true}
          value={values?.newPassword}
        />
        <TextError>
          {errors?.newPassword && touched?.newPassword && errors?.newPassword}
        </TextError>
        <FormikControl
          control="input"
          label="Repetir nova senha"
          name="confirmPassword"
          onBlur={handleBlur}
          onChange={handleChange}
          type="password"
          upLabel={true}
          value={values?.confirmPassword}
        />
        <TextError>
          {errors?.confirmPassword &&
            touched?.confirmPassword &&
            errors?.confirmPassword}
        </TextError>
      </ConfigDataForm>
    </ConfigWrapper>
  );
}
