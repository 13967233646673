/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import InviteForm from "../../../components/FormikElements/InviteForm";
import { Flatlist } from "../../../components/List";
import { ProfessionalListItem } from "../../../components/ListItens";
import api from "../../../services/api";
import useWindowSize from "../../../utils/WindowSize";
import { Main } from "./styles";

export default function ProfessionalList() {
  const [professionals, setProfessionals] = useState([]);
  const { width } = useWindowSize();
  const [loading, setLoading] = useState(true);

  const submitForm = async values => {
    setLoading(true);
    try {
      const request = {
        data: {
          attributes: {
            name: values.name,
            email: values.email,
            phone: values.phone,
            city: values.city,
            message: values.message,
          },
        },
      };
      const { data } = await api.post("/clinics/invites", request);
      if (data) {
        toast.success("Convite enviado com sucesso!");
      }
    } catch (error) {
      toast.error("Não foi possivel enviar o convite!");
    } finally {
      setLoading(false);
    }
  };

  const schema = Yup.object({
    name: Yup.string()
      .min(3, "O nome deve ter pelo menos 3 caracteres")
      .required("Nome é obrigatório!"),
    email: Yup.string()
      .email("Email deve ser um Email válido")
      .required("Email é obrigatório!"),
    phone: Yup.string().optional(),
    city: Yup.string().optional(),
    message: Yup.string().optional(),
    newSplitPercentage: Yup.number()
      .typeError("O valor deve ser um número")
      .optional()
      .nullable()
      .min(0, "O valor mínimo é 0")
      .max(100, "O valor máximo é 100")
      .integer("O valor deve ser um número inteiro"),
    newPreferOwnMeetingLink: Yup.boolean().optional(),
    newOwnMeetingLink: Yup.string().when("newPreferOwnMeetingLink", {
      is: true,
      then: Yup.string()
        .nullable()
        .url("Link de reunião deve ser uma URL válida")
        .required("Link de reunião é obrigatório"),
    }),
  });

  const form = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      city: "",
      message: "",
      newSplitPercentage: 0,
      newOwnMeetingLink: null,
      newPreferOwnMeetingLink: false,
    },
    validationSchema: schema,
    onSubmit: values => submitForm(values),
    validateOnChange: true,
  });

  const fetchProfessionals = useCallback(async () => {
    try {
      const { data } = await api.get("/clinics/my_professionals");
      const serializerProfessionals = data.data.map(item => ({
        id: item.id,
        attributes: {
          ...item.attributes,
        },
      }));
      setProfessionals(serializerProfessionals);
    } catch (error) {
      toast.error("Não foi possível carregar lista de profissionais");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDelete = async id => {
    try {
      const { data } = await api.patch(`/clinics/professionals/${id}/remove`);
      if (data) {
        setProfessionals(prev => prev.filter(item => item.id !== id));
        toast.success("Profissional removido com sucesso");
      }
    } catch (error) {
      toast.error("Não foi possível remover o profissional");
    }
  };

  const updateOwnMeetingLink = useCallback(
    async ({ id, values }) => {
      setLoading(true);
      try {
        form.setFieldValue("newOwnMeetingLink", null);
        form.setFieldValue("newPreferOwnMeetingLink", false);
        const { data } = await api.patch(
          `/clinics/professionals/${id}/own_meeting_link`,
          {
            data: {
              attributes: {
                newOwnMeetingLink: values.newOwnMeetingLink,
                newPreferOwnMeetingLink: values.newPreferOwnMeetingLink,
              },
            },
          },
        );
        if (data) {
          toast.success("Link de reunião atualizado com sucesso!");
        }
      } catch (error) {
        toast.error("Não foi possível atualizar o link de reunião!");
      } finally {
        fetchProfessionals();
      }
    },
    [fetchProfessionals],
  );

  const deleteOwnMeetingLink = useCallback(
    async ({ id }) => {
      setLoading(true);
      try {
        form.setFieldValue("newOwnMeetingLink", null);
        form.setFieldValue("newPreferOwnMeetingLink", false);
        const { data } = await api.patch(
          `/clinics/professionals/${id}/own_meeting_link`,
          {
            data: {
              attributes: {
                newOwnMeetingLink: null,
                newPreferOwnMeetingLink: false,
              },
            },
          },
        );
        if (data) {
          toast.success("Link de reunião atualizado com sucesso!");
        }
      } catch (error) {
        toast.error("Não foi possível atualizar o link de reunião!");
      } finally {
        fetchProfessionals();
      }
    },
    [fetchProfessionals],
  );

  const handleSubmitPassOn = useCallback(
    async ({ id, values }) => {
      try {
        const { data } = await api.patch(
          `/clinics/professionals/${id}/split_percentage`,
          {
            data: {
              attributes: {
                newSplitPercentage: values.newSplitPercentage,
              },
            },
          },
        );
        if (data) {
          form.setFieldValue(
            "newSplitPercentage",
            data.data.attributes.clinicSplitPercentage,
          );
          toast.success("Repasse definido com sucesso!");
        }
      } catch (error) {
        toast.error("Não foi possível definir o repasse!");
      } finally {
        fetchProfessionals();
      }
    },
    [form.values.newSplitPercentage, fetchProfessionals],
  );

  useEffect(() => {
    fetchProfessionals();
  }, [fetchProfessionals]);

  return (
    <Main>
      <Flatlist
        type="professional"
        data={professionals}
        filterObject={item => item.attributes.firstName}
        placeholder="Pesquise um Profissional"
        renderItem={item => (
          <ProfessionalListItem
            clinic
            data={item}
            deleteOwnMeetingLink={deleteOwnMeetingLink}
            form={form}
            handleDelete={handleDelete}
            handleSubmitPassOn={handleSubmitPassOn}
            key={item.id}
            updateOwnMeetingLink={updateOwnMeetingLink}
            width={width}
          />
        )}
        loading={loading}
      />

      <InviteForm
        form={form}
        handleSubmit={values => submitForm(values)}
        type="professional"
      />
    </Main>
  );
}
