import { Lock, Unlock } from 'react-feather';
import styled from 'styled-components';
import {
  BREAKPOINT_MD_PX,
  BREAKPOINT_XL_PX,
} from '../../../styles/globalStyles';
import { moveLeftToRight } from '../../../styles/keyframes';

export const Main = styled.section`
  animation: ${moveLeftToRight} 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

export const GridContent = styled.section`
  border: 1px solid ${({ theme }) => theme.ExtralightGray};
  border-radius: 0.5rem;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-top: 2rem;
  padding: 1rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  }
`;

export const GridCardContent = styled.div`
  background-color: ${({ theme, block }) =>
    block ? theme.smallPurple : theme.primary};
  padding: 8px 8px 12px 8px;
  border-radius: 5px;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 10px;
`;

export const ScrollViewContent = styled.div`
  margin-top: 10px;
`;

export const TitleDay = styled.h1`
  color: ${({ theme }) => theme.primary};
  font-size: 18px;
  font-weight: 650;
`;

export const CardTextHour = styled.p`
  font-size: 17px;
  font-weight: ${({ weigth }) => weigth || ''};
  line-height: 21px;
  text-align: start;
  color: ${({ color }) => color || '#fff'};

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    font-size: 12px;
  }
`;

export const CardText = styled(CardTextHour)`
  font-size: 12px;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    font-size: 10px;
  }
`;

export const ContainerIcon = styled.button`
  justify-self: center;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  height: 80%;
  border: solid 1px gray;
  :hover {
    background-color: #f5f5f5;
  }
`;

export const PadlockLock = styled(Lock)`
  align-self: center;
  color: black;
`;

export const PadlockUnlock = styled(Unlock)`
  align-self: center;
  color: black;
`;

export const CheckboxContainer = styled.div`
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1rem 7rem 1fr;
  margin-bottom: 0.5rem;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })``;

export const Label = styled.label`
  font-size: 1.2rem;
  margin-left: 0.5rem;
`;

export const FormData = styled.section`
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  column-gap: 2rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
`;

export const FormTimesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const CardFormType = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.ExtralightGray};
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const CardFormConfig = styled(CardFormType)``;
