import React from "react";
import { Switch } from "react-router-dom";
import NotificationList from "../../components/NotificationList";
import CalendarClientScreen from "../../screens/Client/Calendar";
import ClientConfigs from "../../screens/Client/Config";
import ContentListScreen from "../../screens/Client/Content";
import ContentList from "../../screens/Client/ContentList";
import ContentShow from "../../screens/Client/ContentShow";
import ClientDashboard from "../../screens/Client/Dashboard";
import ClientFinanceScreen from "../../screens/Client/Finance";
import ClientMessageList from "../../screens/Client/Mensagens";
import PaymentScreen from "../../screens/Client/Professionals/Payment";
import ProfessionalListScreen from "../../screens/Client/Professionals/ProfessionalsList";
import { ScheduleForm } from "../../screens/Client/Professionals/Schedule";
import SuccessPaymentScreen from "../../screens/Client/Professionals/Success";
import ReviewProfessional from "../../screens/Client/Review";
import SchedulesReportClient from "../../screens/Client/SchedulesReport";
import Contents from "../../screens/Contents";
import MedicalRecord from "../../screens/Professional/MedicalRecord";
import { NewCardPayment } from "../../screens/Professional/ProfessionalUpdate/Subscribe";
import ProfessionalsClinic from "../../screens/Public/ProfessionalsClinic";
import { VideoSDK } from "../../screens/VideoSDK";
import { ClientRoutes } from "./clientRoutes";
// import { Meeting } from "../../screens/Meeting";

export function PrivateRouteClient() {
  const clinicName = window.location.pathname;

  return (
    <Switch>
      <ClientRoutes
        exact
        path="/client/dashboard"
        component={ClientDashboard}
      />

      <ClientRoutes exact path="/client/config" component={ClientConfigs} />

      <ClientRoutes
        exact
        path="/client/notifications"
        component={NotificationList}
      />

      <ClientRoutes
        exact
        path="/client/subscription/add_new_payment"
        component={NewCardPayment}
      />

      <ClientRoutes
        exact
        path="/client/calendar"
        component={CalendarClientScreen}
      />

      <ClientRoutes
        exact
        path="/client/schedules_report"
        component={SchedulesReportClient}
      />

      <ClientRoutes
        exact
        path="/client/messages"
        component={ClientMessageList}
      />

      <ClientRoutes
        exact
        path="/client/finances"
        component={ClientFinanceScreen}
      />

      <ClientRoutes
        exact
        path="/client/content"
        component={ContentListScreen}
      />

      <ClientRoutes
        exact
        path="/client/medical_record"
        component={MedicalRecord}
      />

      <ClientRoutes
        exact
        path="/client/content_list/:id"
        component={ContentList}
      />

      <ClientRoutes exact path="/client/content/:id" component={ContentShow} />

      <ClientRoutes
        exact
        path="/client/professionals"
        component={ProfessionalListScreen}
      />

      <ClientRoutes
        exact
        path="/client/review/:id"
        component={ReviewProfessional}
      />

      <ClientRoutes
        exact
        path="/client/schedule/:id"
        component={ScheduleForm}
      />

      <ClientRoutes
        exact
        path="/client/payment/:id"
        component={PaymentScreen}
      />

      <ClientRoutes
        exact
        path="/client/payment/success"
        component={SuccessPaymentScreen}
      />

      <ClientRoutes exact path="/client/contents" component={Contents} />

      <ClientRoutes
        exact
        path="/client/content_list/:id"
        component={ContentList}
      />

      <ClientRoutes exact path="/client/content/:id" component={ContentShow} />

      {/* <ClientRoutes exact path="/client/meeting" component={Meeting} /> */}

      <ClientRoutes exact path="/client/meeting" component={VideoSDK} />

      <ClientRoutes
        exact
        path={`${clinicName}`}
        component={ProfessionalsClinic}
      />
    </Switch>
  );
}
