/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  CalendarCard,
  CalendarClinicCard,
} from '../../../../components/EventElements';
import LoaderSpinner from '../../../../components/LoaderSpinner';
import EmptyEvent from '../../../../images/empty-dashboard.png';
import { PtBr } from '../../../../services/pt_br';
import {
  CalendarContainer,
  CardOverview,
  CardOverviewTitle,
  CardOverviewValue,
  ContainerImg,
  TimeItemList,
  TimeText,
  WeekItemList,
  WeekList,
  WeekText,
  WrapperOverview,
} from '../styles';

export function CalendarItemList({ ...props }) {
  const { item, schedules, date } = props;
  const handleDate = (date) => {
    return moment(date).format('HH:mm');
  };

  const handleSchedule = () => {
    return (
      schedules?.filter(
        (dt) =>
          dt.attributes.startDate === date &&
          dt.attributes.startTime === moment(item).format('HH:mm:ss')
      )[0] || null
    );
  };

  return (
    <TimeItemList>
      <TimeText>{handleDate(item)}</TimeText>
      {handleSchedule() && <CalendarCard event={handleSchedule()} />}
    </TimeItemList>
  );
}

function WeekItem({ ...props }) {
  const { date, schedules, daysTimes, setLoading } = props;
  const [timeBlocks, setTimeBlocks] = useState([]);
  const formatedDate = moment(date).format('YYYY-MM-DD');
  const currentDate = moment().format('YYYY-MM-DD');
  const currentDay = moment(date).format('ddd').toLowerCase();

  useEffect(() => {
    const blockTimes = daysTimes?.filter((dt) => dt.day === currentDay);

    const schedulesTimes = schedules?.filter(
      (schedule) => schedule.attributes.startDate === formatedDate
    );

    const timesBlocks = [...blockTimes, ...schedulesTimes]?.reduce(
      (acc, curr) => {
        if (
          schedulesTimes?.some(
            (item) => item.attributes.startTime === curr.attributes.startTime
          )
        ) {
          const index = schedulesTimes?.findIndex(
            (item) => item.attributes.startTime === curr.attributes.startTime
          );
          if (acc.includes(schedulesTimes[index])) {
            return acc;
          }
          acc.push(schedulesTimes[index]);
          return acc;
        }
        acc.push(curr);
        return acc;
      },
      []
    );

    setTimeBlocks(
      timesBlocks.sort((a, b) => {
        const timeA = moment(a.attributes.startTime, 'HH:mm');
        const timeB = moment(b.attributes.startTime, 'HH:mm');

        return timeA.isBefore(timeB) ? -1 : 1;
      })
    );
  }, [date, schedules]);

  function countUniqueIds(arr) {
    let ids = {};

    for (let i = 0; i < arr.length; i++) {
      let id = arr[i]?.attributes?.professional?.id;
      if (id) {
        ids[id] = true;
      }
    }

    // O número de IDs únicos é o número de chaves no objeto 'ids'
    let count = Object.keys(ids).length;

    return count;
  }

  const professionalServingDay = countUniqueIds(timeBlocks);

  const sessionsPerProfessional = (
    timeBlocks.length / professionalServingDay
  ).toFixed(1);

  return (
    <WeekItemList
      active={'true'}
      current={formatedDate === currentDate ? 'true' : 'false'}
    >
      <WeekItemList>
        <WeekText
          active={'true'}
          current={formatedDate === currentDate ? 'true' : 'false'}
        >
          {date.getDate()} de {PtBr.months_name[date.getMonth()].toLowerCase()}
        </WeekText>
        <WeekText
          active={'true'}
          current={formatedDate === currentDate ? 'true' : 'false'}
        >
          {PtBr.days_name[date.getDay()]}
        </WeekText>
        <WrapperOverview
          current={formatedDate === currentDate ? 'true' : 'false'}
        >
          <CardOverview>
            <CardOverviewTitle>Agendamentos para o dia</CardOverviewTitle>
            <CardOverviewValue>{timeBlocks.length}</CardOverviewValue>
          </CardOverview>
          <CardOverview>
            <CardOverviewTitle>
              Profissionais atendendo no dia
            </CardOverviewTitle>
            <CardOverviewValue>{professionalServingDay}</CardOverviewValue>
          </CardOverview>
          <CardOverview>
            <CardOverviewTitle>Sessões por profissional</CardOverviewTitle>
            <CardOverviewValue>
              {isNaN(sessionsPerProfessional) ? 0 : sessionsPerProfessional}
            </CardOverviewValue>
          </CardOverview>
        </WrapperOverview>
      </WeekItemList>
      {timeBlocks?.length > 0 ? (
        <WeekList>
          {timeBlocks?.map((event) => (
            <CalendarClinicCard
              key={event.attributes.startTime}
              event={event}
              calendarProfessional={true}
              setLoading={setLoading}
            />
          ))}
        </WeekList>
      ) : (
        <WeekList>
          <ContainerImg>
            <img
              src={EmptyEvent}
              alt="empty"
              style={{ width: '70%', alignSelf: 'center' }}
            />
            <p
              style={{
                color: '#999EBC',
                fontSize: '0.8rem',
                marginTop: '0.7rem',
                textAlign: 'center',
              }}
            >
              Sem agendamento para o dia.
            </p>
          </ContainerImg>
        </WeekList>
      )}
    </WeekItemList>
  );
}

export default function CalendarComponent({ ...props }) {
  const { date, dayColumn, schedules, loading, setLoading } = props;
  const [days, setDays] = useState([]);
  const [daysTimes, setDaysTimes] = useState([]);

  useEffect(() => {
    const blockTimes = [];
    const getDates = () => {
      const numberWeek = [];
      for (let i = 0; i < dayColumn; i++) {
        numberWeek.push(moment(date).add(i, 'days').toDate());
      }
      return numberWeek;
    };
    const getDays = getDates();
    const daysFilter = getDays?.map((item) => moment(item).format('ddd'));
    const filterTimes = blockTimes?.filter(
      (item) => daysFilter.includes(item.day) && item
    );
    setDaysTimes(filterTimes);
    setDays(getDays);
  }, [schedules, date]);

  useEffect(() => {
    if (days?.length > 0 && daysTimes?.length > 0) {
      setLoading(false);
    }
  }, [days, daysTimes]);

  return (
    <CalendarContainer>
      {loading ? (
        <LoaderSpinner logo />
      ) : (
        days?.map((item) => {
          return (
            <WeekItem
              key={item}
              date={item}
              schedules={schedules}
              daysTimes={daysTimes}
              setLoading={setLoading}
            />
          );
        })
      )}
    </CalendarContainer>
  );
}
