/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from "@material-ui/core";
import { FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { StaticButton } from "../../../components/Button";
import LoaderSpinner from "../../../components/LoaderSpinner";
import useAuth from "../../../hooks/auth";
import api from "../../../services/api";
import theme from "../../../theme";
import { validateCNPJ } from "../../../utils/validateCNPJ";
import validateCPF from "../../../utils/validateCPF";
import validatedInfoBank from "../../../utils/validateInfoBank";
import { ConfigWrapper } from "../../Professional/Config/styles";
import {
  CustomPackages,
  DeleteAccount,
  Terms,
  UpdateAdress,
  UpdateBankAccount,
  UpdateBasicData,
  UpdatePassword,
  UpdateResponsibleData,
  UpdateTypesReceipts,
} from "./components";
import {
  ConfigBody,
  ConfigMain,
  MenuHeader,
  MenuOption,
  OptionText,
  RowLine,
} from "./styles";

function TopMenuBar({ ...props }) {
  const {
    options,
    setSelected,
    selected,
    validateInfoClinic,
    validateInfoResponsible,
    validateInfoAddress,
    validateInfoBank,
  } = props;

  return (
    <MenuHeader>
      {options?.map(item => (
        <MenuOption
          active={selected === item}
          key={item}
          onClick={() => setSelected(item)}
          type="button"
          style={{
            backgroundColor: `${item === "Excluir conta" && theme.red}`,
          }}
        >
          <OptionText
            active={selected === item}
            style={{
              color: `${item === "Excluir conta" && theme.white}`,
            }}
          >
            {item}
          </OptionText>
          {item === "Dados básicos" && !validateInfoClinic && (
            <span
              style={{
                width: "0.7rem",
                height: "0.7rem",
                backgroundColor: "#EB5757",
                borderRadius: "50%",
                position: "absolute",
                top: "-0.4rem",
                right: "0.6rem",
              }}
            ></span>
          )}
          {item === "Responsável técnico" && !validateInfoResponsible && (
            <span
              style={{
                width: "0.7rem",
                height: "0.7rem",
                backgroundColor: "#EB5757",
                borderRadius: "50%",
                position: "absolute",
                top: "-0.4rem",
                right: "0.6rem",
              }}
            ></span>
          )}
          {item === "Endereço" && !validateInfoAddress && (
            <span
              style={{
                width: "0.7rem",
                height: "0.7rem",
                backgroundColor: "#EB5757",
                borderRadius: "50%",
                position: "absolute",
                top: "-0.4rem",
                right: "0.6rem",
              }}
            ></span>
          )}
          {item === "Dados bancários" && !validateInfoBank && (
            <span
              style={{
                width: "0.7rem",
                height: "0.7rem",
                backgroundColor: "#EB5757",
                borderRadius: "50%",
                position: "absolute",
                top: "-0.4rem",
                right: "0.6rem",
              }}
            ></span>
          )}
        </MenuOption>
      ))}
    </MenuHeader>
  );
}

export default function ClinicProfileConfig() {
  const history = useHistory();
  const { user, updateClinic, updatePassword, signOut } = useAuth();
  const [selected, setSelected] = useState("Dados básicos");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [updateBank, setUpdateBank] = useState(false);
  const [validateInfoClinic, setValidateInfoClinic] = useState(false);
  const [validateInfoAddress, setValidateInfoAddress] = useState(false);
  const [validateInfoBank, setValidateInfoBank] = useState(false);
  const [validateInfoResponsible, setValidateInfoResponsible] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const validateClinic = values =>
    [
      values?.firstName,
      values?.lastName,
      values?.birthday,
      values?.phone,
      values?.documentNumber,
    ]?.every(info => info !== "");

  const validateResponsible = values =>
    [
      values?.responsibleAvatar,
      values?.responsibleName,
      values?.responsibleDocumentNumber,
      values?.responsibleAbout,
    ]?.every(info => info !== "");

  const validateAddress = values =>
    [
      values?.street,
      values?.street_number,
      values?.neighborhood,
      values?.cep,
      values?.country,
      values?.state,
      values?.city,
    ]?.every(info => info !== "");

  const validateBank = values =>
    [
      values?.bankCode,
      values?.bankAg,
      values?.bankCc,
      values?.contaDv,
      values?.accountType,
      values?.paymentDocumentNumber,
    ]?.every(info => info !== "");

  useEffect(() => {
    // Limpar erros ao montar o componente
    form.setErrors({});
  }, []);

  const getPreferencialPayments = values => {
    if (values?.acceptCredit && values?.acceptPix) return "all_payment";
    if (values?.acceptCredit) return "credit_card";
    if (values?.acceptPix) return "pix";
  };

  const submitDeleteAccount = async () => {
    setSubmitLoading(true);
    try {
      await api.delete(`/clinics/${user.id}`, {
        data: { reason: form.values.reason },
      });
      toast.success("Conta excluída com sucesso");
      signOut();
    } catch (error) {
      toast.error("Erro ao excluir conta");
    } finally {
      setSubmitLoading(false);
    }
  };

  const submitForm = async values => {
    try {
      setSubmitLoading(true);
      const success = await updateClinic(values, user.id);
      if (success) {
        toast.success("Cadastro atualizado com sucesso!");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  const submitFormSecurity = async values => {
    setSubmitLoading(true);
    try {
      const data = await updatePassword(values, user?.type, user?.id);
      if (data.error) {
        return toast.error("Senha inválida");
      }
      if (data) {
        return toast.success("Senha alterada com sucesso");
      }
    } catch (error) {
      toast.error("Erro ao alterar senha");
    } finally {
      setSubmitLoading(false);
      history.push({
        pathname: "/client/config",
        state: { selected: selected },
      });
    }
  };

  const handleCreateCustomPackages = async values => {
    try {
      setSubmitLoading(true);
      const payload = {
        data: {
          attributes: {
            name: values?.namePackage,
            price: values?.pricePackage,
            numberOfAppointments: Number(values?.numberOfAppointments),
          },
        },
      };
      const { data } = await api.post("/clinics/appointment_packages", payload);
      if (data) {
        toast.success("Plano personalizado criado com sucesso");
      }
    } catch (error) {
      toast.error("Erro ao criar plano personalizado");
    } finally {
      form.setFieldValue("namePackage", "");
      form.setFieldValue("numberOfAppointments", "");
      form.setFieldValue("pricePackage", "");
      setSubmitLoading(false);
    }
  };

  const handleUpdateCustomPackages = async values => {
    try {
      setSubmitLoading(true);
      const payload = {
        data: {
          attributes: {
            name: values?.editNamePackage,
            price: values?.editPricePackage,
            numberOfAppointments: Number(values?.editNumberOfAppointments),
          },
        },
      };
      const { data } = await api.patch(
        `/clinics/appointment_packages/${values?.packageId}`,
        payload,
      );
      if (data) {
        toast.success("Plano personalizado atualizado com sucesso");
      }
    } catch (error) {
      toast.error("Erro ao atualizar plano personalizado");
    } finally {
      form.setFieldValue("packageId", null);
      form.setFieldValue("editNamePackage", "");
      form.setFieldValue("editNumberOfAppointments", "");
      form.setFieldValue("editPricePackage", "");
      setSubmitLoading(false);
    }
  };

  const handleDeleteCustomPackages = async id => {
    try {
      setSubmitLoading(true);
      const { data } = await api.delete(`/clinics/appointment_packages/${id}`);
      if (data) {
        toast.success("Plano personalizado deletado com sucesso");
      }
    } catch (error) {
      toast.error("Erro ao deletar plano personalizado");
    } finally {
      form.setFieldValue("packageId", null);
      form.setFieldValue("isDelete", false);
      setSubmitLoading(false);
    }
  };

  const submitFormPackages = async values => {
    if (values?.isDelete && values?.packageId) {
      handleDeleteCustomPackages(values?.packageId);
    } else if (values?.packageId) {
      handleUpdateCustomPackages(values);
    } else {
      handleCreateCustomPackages(values);
    }
  };

  const schemaInfoClinic = Yup.object({
    avatar: Yup.string().optional().nullable(),
    banner: Yup.string().optional().nullable(),
    bannerColor: Yup.string().optional().nullable(),
    secondaryBanner: Yup.string().optional().nullable(),
    name: Yup.string().required("Nome é obrigatório!"),
    description: Yup.string().required("Descrição é obrigatória!"),
    email: Yup.string()
      .email("Email invalido")
      .required("Email é obrigatório!"),
    phone: Yup.string().required("Telefone é obrigatório!"),
    documentNumber: Yup.string().test({
      name: "isValid",
      exclusive: false,
      params: {},
      message: "CPF/CNPJ inválido",
      test(value) {
        if (value?.replace(/[^\d]+/g, "").length === 11)
          return validateCPF(value || "");
        if (value?.replace(/[^\d]+/g, "").length === 14)
          return validateCNPJ(value || "");
      },
    }),
    preferOwnMeetingLink: Yup.boolean().optional(),
    ownMeetingLink: Yup.string().when("preferOwnMeetingLink", {
      is: true,
      then: Yup.string().required("Link da reunião é obrigatório!"),
    }),
  });

  const schemaInfoResponsible = Yup.object({
    responsibleAvatar: Yup.string().optional(),
    responsibleName: Yup.string().required("Nome é obrigatório!"),
    responsibleDocumentNumber: Yup.string().required("CRP é obrigatório!"),
    responsibleAbout: Yup.string().required("Descrição é obrigatória!"),
  });

  const schemaAddress = Yup.object({
    cep: Yup.string()
      .required("CEP é obrigatorio")
      .test({
        name: "isValid",
        exclusive: false,
        params: {},
        message: "CEP inválido",
        test(value) {
          const maskDefaultValue = "_";
          const str = value;
          const result = str?.search(maskDefaultValue);
          return result > -1 ? false : true;
        },
      }),
    street: Yup.string().required("Endereço é obrigatório!"),
    street_number: Yup.string().required("Número é obrigatório!"),
    complement: Yup.string().optional(),
    neighborhood: Yup.string().required("Bairro é obrigatório!"),
    city: Yup.string().required("Cidade é obrigatório!"),
    state: Yup.string().required("Estado é obrigatório!"),
    country: Yup.string().required("Pais é obrigatório!"),
  });

  const schemaTypesReceipts = Yup.object({
    acceptCredit: Yup.boolean().optional(),
    acceptPix: Yup.boolean().optional(),
    postPaid: Yup.boolean().required("Pós-pago é obrigatório"),
    prePaid: Yup.boolean().required("Pré-pago é obrigatório"),
    postPaidDay: Yup.number().when("postPaid", {
      is: true,
      then: Yup.number()
        .min(1, "Dia do pagamento deve ser maior que 1")
        .max(31, "Dia do pagamento deve ser menor que 31")
        .required("Dia do pagamento é obrigatório"),
    }),
    externalPayment: Yup.boolean().required("Pagamento externo é obrigatório"),
  });

  const schemaBank = Yup.object({
    bankCode: Yup.string().required("Banco é obrigatório"),
    bankAg: Yup.string()
      .required("Agência da conta é obrigatório")
      .nullable()
      .test({
        name: "isValid",
        exclusive: false,
        params: {},
        message: "Informe apenas números",
        test(value) {
          if (!value) return true;
          return validatedInfoBank(value);
        },
      }),
    agenciaDv: Yup.string()
      .optional()
      .nullable()
      .test({
        name: "isValid",
        exclusive: false,
        params: {},
        message: "Informe apenas números",
        test(value) {
          if (!value) return true;
          return validatedInfoBank(value);
        },
      }),
    bankCc: Yup.string()
      .required("Número da conta é obrigatório")
      .nullable()
      .test({
        name: "isValid",
        exclusive: false,
        params: {},
        message: "Informe apenas números",
        test(value) {
          if (!value) return true;
          return validatedInfoBank(value);
        },
      }),
    contaDv: Yup.string()
      .required("Dígito da conta é obrigatório")
      .nullable()
      .test({
        name: "isValid",
        exclusive: false,
        params: {},
        message: "Informe apenas números",
        test(value) {
          if (!value) return true;
          return validatedInfoBank(value);
        },
      }),
    accountType: Yup.string().required("Tipo da conta é obrigatório"),
    paymentName: Yup.string().required("Nome do banco é obrigatório"),
    paymentDocumentNumber: Yup.string().test({
      name: "isValid",
      exclusive: false,
      params: {},
      message: "CPF/CNPJ inválido",
      test(value) {
        if (value?.replace(/[^\d]+/g, "").length === 11)
          return validateCPF(value || "");
        if (value?.replace(/[^\d]+/g, "").length === 14)
          return validateCNPJ(value || "");
      },
    }),
  });

  const schemaSecurity = Yup.object({
    password: Yup.string().required("Senha atual é obrigatória"),
    newPassword: Yup.string()
      .min(8, "A senha deve possuir no mínimo 8 caracteres")
      .required("Nova senha é obrigatória"),
    confirmPassword: Yup.string()
      .required("Confirmação da senha é obrigatorio!")
      .oneOf([Yup.ref("newPassword"), ""], "As senhas devem ser iguais."),
  });

  const schemaPackages = Yup.object({
    packageId: Yup.string().optional().nullable(),
    editNamePackage: Yup.string().when(["packageId", "isDelete"], {
      is: (packageId, isDelete) => packageId && !isDelete,
      then: Yup.string().required("Campo obrigatório"),
      otherwise: Yup.string().optional(),
    }),
    editNumberOfAppointments: Yup.string().when(["packageId", "isDelete"], {
      is: (packageId, isDelete) => packageId && !isDelete,
      then: Yup.string()
        .matches(/^\d+$/, "Deve conter apenas números")
        .required("Campo obrigatório"),
      otherwise: Yup.string().optional(),
    }),
    editPricePackage: Yup.number().when(["packageId", "isDelete"], {
      is: (packageId, isDelete) => packageId && !isDelete,
      then: Yup.number()
        .typeError("Deve ser um número")
        .required("Campo obrigatório"),
      otherwise: Yup.number().optional(),
    }),
    namePackage: Yup.string().when("packageId", {
      is: value => !value,
      then: Yup.string().required("Campo obrigatório"),
    }),
    numberOfAppointments: Yup.string().when("packageId", {
      is: value => !value,
      then: Yup.string()
        .matches(/^\d+$/, "Deve conter apenas números")
        .required("Campo obrigatório"),
    }),
    pricePackage: Yup.number().when("packageId", {
      is: value => !value,
      then: Yup.number()
        .typeError("Deve ser um número")
        .required("Campo obrigatório"),
    }),
  });

  const schemas = () => {
    switch (selected) {
      case "Dados básicos":
        return schemaInfoClinic;
      case "Responsável técnico":
        return schemaInfoResponsible;
      case "Endereço":
        return schemaAddress;
      case "Dados bancários":
        return schemaBank;
      case "Formas de Recebimento":
        return schemaTypesReceipts;
      case "Pacotes Personalizados":
        return schemaPackages;
      case "Segurança":
        return schemaSecurity;
      default:
        return schemaInfoClinic;
    }
  };

  const form = useFormik({
    initialValues: {
      // Dados Básicos
      avatar: user?.avatar || null,
      banner: user?.banner || null,
      bannerColor: user?.bannerColor || null,
      secondaryBanner: user?.secondaryBanner || null,
      name: user?.firstName || "",
      description: user?.description || "",
      email: user?.email || "",
      phone: user?.phone || "",
      documentNumber: user?.documentNumber || "",
      hiddenFields: user?.hiddenFields || [],
      preferOwnMeetingLink: user?.preferOwnMeetingLink || false,
      ownMeetingLink: user?.ownMeetingLink || "",

      // Responsável Técnico
      responsibleAvatar: user?.responsibleAvatar || null,
      responsibleName: user?.responsibleName || "",
      responsibleDocumentNumber: user?.responsibleDocumentNumber || "",
      responsibleAbout: user?.responsibleAbout || "",

      // Endereço
      cep: user?.address?.cep || "",
      street: user?.address?.street || "",
      street_number: user?.address?.street_number || "",
      neighborhood: user?.address?.neighborhood || "",
      city: user?.address?.city || "",
      state: user?.address?.state || "",
      country: user?.address?.country || "",
      complement: user?.address?.complement || "N/A",

      // Dados Bancários
      accountType: user?.paymentAccount?.accountType || "",
      agenciaDv: user?.paymentAccount?.agenciaDv || "",
      bankAg: user?.paymentAccount?.bankAg || "",
      bankCc: user?.paymentAccount?.bankCc || "",
      bankCode: user?.paymentAccount?.bankCode || "",
      contaDv: user?.paymentAccount?.contaDv || "",
      paymentName: user?.paymentAccount?.name || "",
      paymentDocumentNumber: user?.paymentAccount?.documentNumber || null,

      // Formas de Recebimento
      preferentialPayment: getPreferencialPayments(user),
      acceptCredit: user?.acceptCredit || false,
      acceptPix: user?.acceptPix || false,
      postPaid: user?.postPaid || false,
      postPaidDay: user?.postPaidDay || 10,
      prePaid: user?.prePaid || true,
      externalPayment: user?.externalPayment || false,

      // Pacotes Personalizados
      namePackage: "",
      numberOfAppointments: "",
      pricePackage: "",
      packageId: null,
      editNamePackage: "",
      editNumberOfAppointments: "",
      editPricePackage: "",
      isDelete: false,

      // Delete Account Reason
      reason: "",
    },
    validationSchema: schemas(),

    onSubmit: values => {
      if (selected === "Pacotes Personalizados") {
        return submitFormPackages(values);
      }
      if (selected === "Segurança") {
        return submitFormSecurity(values);
      }
      return submitForm(values);
    },
  });

  const handleHiddenFields = (event, type) => {
    if (event.target.checked) {
      form.setFieldValue(
        "hiddenFields",
        form.values.hiddenFields.filter(value => value !== type),
      );
    } else {
      form.setFieldValue("hiddenFields", [...form.values.hiddenFields, type]);
    }
  };

  const options = [
    "Dados básicos",
    "Responsável técnico",
    "Endereço",
    "Dados bancários",
    "Formas de Recebimento",
    "Pacotes Personalizados",
    "Segurança",
    "Termos de uso",
    "Excluir conta",
  ];

  const validateInfos = values => {
    setDisabled(values);
  };

  const storeAsBase64 = (file, type) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      form.setFieldValue(type, reader.result);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    setValidateInfoAddress(validateAddress(form.values));
    setValidateInfoBank(validateBank(form.values));
    setValidateInfoClinic(validateClinic(form.values));
    setValidateInfoResponsible(validateResponsible(form.values));
  }, [form.values]);

  return (
    <FormikProvider value={form}>
      <ConfigMain onSubmit={form.handleSubmit}>
        <TopMenuBar
          options={options}
          selected={selected}
          setSelected={setSelected}
          validateInfoClinic={validateInfoClinic}
          validateInfoAddress={validateInfoAddress}
          validateInfoBank={validateInfoBank}
          validateInfoResponsible={validateInfoResponsible}
        />
        <RowLine />
        {submitLoading ? (
          <LoaderSpinner />
        ) : (
          <ConfigBody>
            {selected === "Dados básicos" && (
              <UpdateBasicData
                form={form}
                storeAsBase64={storeAsBase64}
                user={user}
                validateInfos={validateInfos}
                handleHiddenFields={handleHiddenFields}
              />
            )}
            {selected === "Responsável técnico" && (
              <UpdateResponsibleData
                form={form}
                storeAsBase64={storeAsBase64}
                user={user}
                validateInfos={validateInfos}
                handleHiddenFields={handleHiddenFields}
              />
            )}
            {selected === "Endereço" && (
              <UpdateAdress
                form={form}
                validateInfos={validateInfos}
                user={user}
              />
            )}
            {selected === "Dados bancários" && (
              <UpdateBankAccount
                form={form}
                setUpdateBank={setUpdateBank}
                updateBank={updateBank}
                user={user}
                validateInfos={validateInfos}
              />
            )}
            {selected === "Formas de Recebimento" && (
              <UpdateTypesReceipts
                form={form}
                user={user}
                validateInfos={validateInfos}
              />
            )}
            {selected === "Pacotes Personalizados" && (
              <CustomPackages form={form} />
            )}
            {selected === "Segurança" && (
              <UpdatePassword form={form} validateInfos={validateInfos} />
            )}
            {selected === "Termos de uso" && <Terms />}
            {selected === "Excluir conta" && <DeleteAccount form={form} />}
            {selected !== "Pacotes Personalizados" && (
              <ConfigWrapper style={{ marginTop: "3rem" }}>
                {selected !== "Excluir conta" ? (
                  <StaticButton
                    disabled={disabled}
                    height="3rem"
                    title={
                      submitLoading ? "Carregando..." : "Salvar alterações"
                    }
                  />
                ) : (
                  <StaticButton
                    type="button"
                    buttonColor={theme.red}
                    onClick={() => setModalDelete(true)}
                    height="3rem"
                    title={submitLoading ? "Carregando..." : "Excluir conta"}
                  />
                )}
              </ConfigWrapper>
            )}
          </ConfigBody>
        )}
      </ConfigMain>

      {modalDelete && (
        <Modal open>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                width: "30%",
                height: "30%",
                padding: "3rem 0",
                backgroundColor: "white",
                borderRadius: "1rem",
                boxShadow: "0 0 0.5rem rgba(0, 0, 0, 0.1)",
              }}
            >
              <h1 style={{ color: "black" }}>Deseja excluir sua conta?</h1>
              <p style={{ color: "black", fontSize: "1rem", width: "80%" }}>
                Ao excluir sua conta, você perderá todos os seus dados, essa
                ação não pode ser desfeita.
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "80%",
                }}
              >
                <StaticButton
                  type="button"
                  height="2.5rem"
                  width="5rem"
                  title="Sim"
                  buttonColor={theme.red}
                  onClick={() => {
                    submitDeleteAccount();
                    setModalDelete(false);
                  }}
                />
                <StaticButton
                  height="2.5rem"
                  width="5rem"
                  title="Não"
                  onClick={() => setModalDelete(false)}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </FormikProvider>
  );
}
