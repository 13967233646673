import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from 'react-feather';
import styled from 'styled-components';
import {
  AnimationWrapper,
  BREAKPOINT_LG_PX,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BigFont,
  CALENDAR_COLUMN_LG,
  CALENDAR_COLUMN_MD,
  CALENDAR_COLUMN_SM,
  CALENDAR_COLUMN_XL,
  MediumBigFont,
  MediumFont,
  SmallFont,
} from '../../../styles/globalStyles';
import { fadeIn, fadeOut } from '../../../styles/keyframes';

export const BodyContainer = styled(AnimationWrapper)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 100%;
`;

export const MenuHeader = styled.section`
  align-items: center;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const CalendarContainer = styled.section`
  align-items: flex-start;
  column-gap: 0.7rem;
  display: grid;
  grid-template-columns: repeat(${CALENDAR_COLUMN_XL}, 1fr);
  justify-items: center;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    grid-template-columns: repeat(${CALENDAR_COLUMN_LG}, 1fr);
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: repeat(${CALENDAR_COLUMN_MD}, 1fr);
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: repeat(${CALENDAR_COLUMN_SM}, 1fr);
  }
`;

export const ButtonsContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    max-width: 350px;
    width: 100%;
  }
`;

export const TypeButtonsContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 80%;
`;

export const CalendarButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.newPrimary};
  border-radius: 5px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 5px;
  padding: 5px;
`;

export const TypeButton = styled.button`
  align-items: center;
  background-color: ${({ theme, selected }) =>
    selected ? theme.primary : theme.color11};
  border-radius: 5px;
  border: none;
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 2px;
  padding: 5px 10px;
`;

export const ArrowLeft = styled(ChevronLeft)`
  color: ${({ theme }) => theme.black};
`;
export const ArrowsLeft = styled(ChevronsLeft)`
  color: ${({ theme }) => theme.black};
`;
export const ArrowRight = styled(ChevronRight)`
  color: ${({ theme }) => theme.black};
`;
export const ArrowsRight = styled(ChevronsRight)`
  color: ${({ theme }) => theme.black};
`;

export const DateInfo = styled.section`
  display: flex;
  padding: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const InfoWeekDay = styled(MediumBigFont)`
  font-weight: 500;
  color: ${({ theme }) => theme.secondary};
`;

export const InfoDay = styled(MediumBigFont)`
  font-weight: 400;
  color: ${({ theme }) => theme.secondary};
`;

export const InfoMonth = styled(BigFont)`
  font-weight: 900;
  color: ${({ theme }) => theme.black};
`;
//

export const TimeItemList = styled.section`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color11};
  display: flex;
  flex-direction: row;
  height: 80px;
  justify-content: flex-start;
  width: 80%;

  @media only screen and(max-width: ${BREAKPOINT_SM_PX}) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
`;
export const TimeText = styled(SmallFont)`
  color: ${({ theme }) => theme.secondary};
  font-weight: 400;
  padding: 0 25px;
`;

export const WeekItemList = styled.section`
  background-color: ${({ theme, current }) =>
    current === 'true' ? theme.newPrimaryAlt : theme.white};
  border-radius: ${({ current }) => (current === 'true' ? '0.325rem' : '0')};
  align-items: ${({ active }) => (active === 'true' ? 'stretch' : 'center')};
  display: flex;
  flex-direction: column;
  justify-content: ${({ active }) =>
    active === 'true' ? 'center' : 'flex-start'};
  max-width: 350px;
  padding: ${({ current }) =>
    current === 'true' ? '0 0.5rem 0.5rem 0.5rem' : '0'};
  width: 100%;
`;

export const WeekList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    margin-top: 1rem;
  }
`;

export const WeekText = styled(MediumFont)`
  background-color: ${({ theme, current }) =>
    current === 'true' ? theme.newPrimaryAlt : 'transparent'};
  font-weight: ${({ active }) => (active === 'true' ? '700' : '400')};
  padding: ${({ active }) => (active === 'true' ? '0' : '25px 25px 0 10px')};
  text-align: center;
  width: 100%;
`;

export const ContainerImg = styled.div`
  align-items: center;
  border-radius: 0.325rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 0.5rem;
  width: 100%;
`;

export const IconArea = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.primary};
  box-shadow: 0 0 5px ${({ theme }) => theme.primary};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: auto;
  padding: 0.2rem 0.5rem;
  position: fixed;
  right: 1rem;
  top: 4rem;
  width: fit-content;
`;

export const WrapperOverview = styled.div`
  background-color: ${({ theme, current }) =>
    current === 'true' ? theme.newPrimaryAlt : theme.white};
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
`;

export const CardOverview = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.white};
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.newPrimary};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CardOverviewTitle = styled(SmallFont)`
  color: ${({ theme }) => theme.lightGray};
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
`;

export const CardOverviewValue = styled(BigFont)`
  color: ${({ theme }) => theme.primary};
  font-weight: 700;
`;

export const SearchContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
  width: 100%;
`;

export const SearchContentMain = styled(SearchContainer)`
  animation: ${fadeIn} 0.3s ease, ${fadeOut} 0.3s ease 2s;
  background-color: rgba(100, 100, 100, 0.4);
  border-radius: 0.25rem;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  left: 0;
  margin-top: 0.5rem;
  padding: 0.3rem 1rem 1rem 0.5rem;
  position: absolute;
  top: 6vh;
  width: 50%;
  z-index: 1;
`;

export const SearchContent = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.newPrimaryAlt};
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.newPrimary};
  color: ${({ theme }) => theme.black};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  justify-content: flex-start;
  margin-top: 0.3rem;
  padding: 0.5rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 100%;

  :hover {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
  }
`;

export const SearchContentAvatar = styled.img`
  border-radius: 50%;
  height: 50px;
  margin-right: 10px;
  width: 50px;
`;

export const SearchContentText = styled.p`
  color: ${({ theme }) => theme.black};
  font-size: 1rem;
  font-weight: 400;
`;

export const InputWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  width: 50%;
`;

export const SearchClose = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.newPrimaryAlt};
  border-radius: 50%;
  border: none;
  color: ${({ theme }) => theme.black};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  height: 2rem;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;

  :hover {
    background-color: ${({ theme }) => theme.pink};
    color: ${({ theme }) => theme.white};
  }
`;
