import React from 'react';
import { FooterButtons } from '../../../components/FooterButtons';
import FormikControl from '../../../components/FormikControl';
import { InputWrapper } from '../../../components/FormikElements/styles';
import { NewSelect } from '../../../components/SelectList';
import theme from '../../../theme';
import handleErrors from '../../../utils/getValuesErrors';
import { getPreferencialPayments } from '../../../utils/preferencialPayments';
import { LoginParagraph, LoginTitle } from '../../Public/Login/styles';

const ValuesField = ['sessionPrice', 'minutes', 'hour'];

export default function AttendanceConfig({ form, next, goBack }) {
  const { values, setFieldValue, errors, touched } = form;
  const preferentialPaymentTypes = ['pix', 'credit_card', 'all_payment'];

  const getPaymensts = (acceptPix, acceptCredit) => {
    const preferentialPayment = [];
    if (acceptPix && acceptCredit) {
      return preferentialPaymentTypes;
    } else if (acceptPix) {
      preferentialPayment.push('pix');
    } else if (acceptCredit) {
      preferentialPayment.push('credit_card');
    }
    return preferentialPayment;
  };

  const preferentialPayment = values.clinicId
    ? getPaymensts(values.acceptPix, values.acceptCredit)
    : preferentialPaymentTypes;

  const handleContinue = () => {
    if (!handleErrors(errors, ValuesField)) {
      next();
    }
  };

  const handleChangeCurrency = (_event, value) => {
    setFieldValue('sessionPrice', value);
  };

  return (
    <InputWrapper>
      <LoginTitle style={{ fontSize: '1.5rem' }}>
        Configurações de Atendimento
      </LoginTitle>
      <LoginParagraph style={{ textAlign: 'left' }}>
        Defina o valor do seu atendimento
      </LoginParagraph>
      <FormikControl
        control="input-currency"
        errors={errors.sessionPrice}
        id="sessionPrice"
        name="sessionPrice"
        label="Preço da sessão"
        onChange={handleChangeCurrency}
        touched={touched.sessionPrice}
        upLabel
        value={values?.sessionPrice}
      />

      <NewSelect
        bgColor={theme.white}
        defaultValue={values.preferentialPayment}
        errors={errors.preferentialPayment}
        label="Tipos de recebimentos"
        name="preferentialPayment"
        onChange={({ value }) => {
          setFieldValue('preferentialPayment', value);
          if (value === 'all_payment') {
            setFieldValue('acceptPix', true);
            setFieldValue('acceptCredit', true);
          } else if (value === 'pix') {
            setFieldValue('acceptPix', true);
            setFieldValue('acceptCredit', false);
          } else if (value === 'credit_card') {
            setFieldValue('acceptPix', false);
            setFieldValue('acceptCredit', true);
          }
        }}
        options={preferentialPayment?.map((type) => {
          return { label: getPreferencialPayments(type), value: type };
        })}
        placeholder="Selecione a forma de recebimento"
        touched={touched.preferentialPayment}
      />

      <FooterButtons
        goBack={() => goBack(values.clinicId)}
        next={handleContinue}
      />
    </InputWrapper>
  );
}
