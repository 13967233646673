/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from 'react';
import useAuth from '../hooks/auth';
import BackgroundImg from '../images/login-background.svg';
import api from '../services/api';
import getProfessionsFilter from '../utils/getProfessionsFilter';

export const EventContext = createContext();

export function EventProvider({ children }) {
  const { user } = useAuth();
  const [events, setEvents] = useState([]);
  const [loadingEvent, setLoadingEvent] = useState(true);
  const [pastEvents, setPastEvents] = useState([]);
  const [loadingPastEvent, setLoadingPastEvent] = useState(true);
  const [eventFilter, setEventFilter] = useState({
    address: '',
    approaches: [],
    labels: [],
    price: '0',
    professionFilter: [],
    professions: [],
    ageGroupFilter: [],
    reasons: [],
    search: '',
    sessionType: [],
    specialities: [],
  });
  const [cities, setCities] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [infoCardCredit, setInfoCardCredit] = useState(null);
  const [notificateAvatar, setNotificateAvatar] = useState(false);
  const [notifications, setNotifications] = useState({
    data: [],
    loading: true,
  });
  const [scheduleInfo, setScheduleInfo] = useState(null);
  const [backgroundImg, setBackgroundImg] = useState(null);
  const [firstEvent, setFirstEvent] = useState(0);
  const defaultType = localStorage.getItem('type');
  const type = user.type || defaultType;

  useEffect(() => {
    const background = new Image();
    background.src = BackgroundImg;
    background.onload = () => setBackgroundImg(background.src);
  }, []);

  const getCities = (professionalsFilter) => {
    const citys = professionalsFilter?.reduce((acc, curr) => {
      if (curr?.attributes.address.city === null) return acc;
      if (acc.includes(curr?.attributes?.address?.city.toLowerCase().trim()))
        return acc;
      acc.push(curr?.attributes?.address?.city.toLowerCase().trim());
      return acc;
    }, []);

    const parseCities = citys?.map((city) => city.toLowerCase()).sort();

    return parseCities;
  };

  function resetFilters() {
    setEventFilter({
      address: '',
      approaches: [],
      labels: [],
      price: '0',
      professions: [],
      professionFilter: [],
      reasons: [],
      search: '',
      sessionType: [],
      specialities: [],
    });
  }

  async function deleteEvent(id) {
    try {
      setLoadingEvent(true);
      const { data } = await api.delete(`/${type}s/schedules/${id}`);
      if (data) {
        const tmp = events?.filter((event) => event.id !== id);
        setEvents(tmp);
        return { message: 'Agendamento cancelado com sucesso!', status: 200 };
      }
    } catch (error) {
      if (error.response.status === 404) {
        return {
          message:
            'Não foi possivel encontrar esse agendamento! Atualize a página e tente novamente!',
          status: error.response.status,
        };
      }
      return {
        message: 'Erro do servidor, tente novamente mais tarde',
        status: error.response.status,
      };
    } finally {
      setLoadingEvent(false);
    }
  }

  async function getProfessionals(controller) {
    try {
      setLoadingEvent(true);
      const { data } = await api.get('/clients/professionals', {
        signal: controller?.signal,
      });
      if (data?.data) {
        const newsProfessionals = getProfessionsFilter(data.data);
        setProfessionals(newsProfessionals);
        const cities = getCities(newsProfessionals);
        setCities(cities);
        return true;
      }
      throw new Error('Não foi possível buscar lista de profissionais');
    } catch (error) {
      if (controller?.signal?.aborted) return;
      return false;
    } finally {
      setLoadingEvent(false);
    }
  }

  async function createEvent(formValues) {
    try {
      setLoadingEvent(true);

      const request = {
        data: {
          attributes: {
            professionalId: formValues.professional.id,
            duration: formValues.duration,
            startDate: formValues.startDate,
            startTime: formValues.startTime,
            appointmentType: formValues.appointmentType,
            postPaid: formValues.postPaid,
            externalPayment: formValues.externalPayment,
            paymentAttributes: {
              paymentMethod: formValues.paymentMethod,
              couponId: formValues.couponId,
              creditCardId: formValues.creditCardId,
            },
            recurrentScheduleAttributes: {
              recurrent: formValues.recurrent,
              duration: formValues.duration,
              frequency: formValues.frequency,
            },
          },
        },
      };

      if (formValues.recurrent === false) {
        delete request.data.attributes.recurrentScheduleAttributes;
      }

      if (formValues.paymentMethod === 'pix') {
        delete request.data.attributes.paymentAttributes.creditCardId;
      }

      if (formValues.paymentMethod === 'external') {
        delete request.data.attributes.paymentAttributes.couponId;
        delete request.data.attributes.paymentAttributes.creditCardId;
      }

      const { data } = await api.post(`/clients/schedules`, request);
      if (data?.data) {
        let schedule = data.data;
        setEvents([schedule, ...events]);
        return { success: true, error: null, data };
      } else {
        throw new Error({
          message: 'Não foi possível criar esse agendamento.',
        });
      }
    } catch (error) {
      return { success: false, error: error.message };
    } finally {
      setScheduleInfo(null);
      setLoadingEvent(false);
      resetFilters();
    }
  }

  return (
    <EventContext.Provider
      value={{
        backgroundImg,
        cities,
        createEvent,
        deleteEvent,
        eventFilter,
        events,
        firstEvent,
        getProfessionals,
        infoCardCredit,
        loadingEvent,
        loadingPastEvent,
        notificateAvatar,
        notifications,
        pastEvents,
        professionals,
        resetFilters,
        scheduleInfo,
        setCities,
        setEventFilter,
        setEvents,
        setFirstEvent,
        setInfoCardCredit,
        setLoadingEvent,
        setLoadingPastEvent,
        setNotificateAvatar,
        setNotifications,
        setPastEvents,
        setProfessionals,
        setScheduleInfo,
      }}
    >
      {children}
    </EventContext.Provider>
  );
}
