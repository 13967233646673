import { Field } from 'formik';
import { Camera, Copy, Plus, Trash2 } from 'react-feather';
import Modal from 'react-modal';
import styled from 'styled-components';
import {
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BigFont,
  ModalBoxWrapper,
  ScrollCardStyle,
  SmallFont,
} from '../../styles/globalStyles';
import { moveBottomToTop } from '../../styles/keyframes';
import { BorderButton, SlimButton } from '../Button';
import { CheckBoxInput, FixedTextInput } from '../Input';

export const RowItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const GridItem = styled.div`
  column-gap: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    column-gap: 0;
    grid-template-columns: 1fr;
  }
`;

export const Main = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  .--input-wrapper {
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;

    &-title {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      color: ${({ theme }) => theme.secundary};
    }

    &-subtitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      padding: 10px 0;
      color: ${({ theme }) => theme.darkGray};
    }

    &-link {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;

      align-self: center;
      padding: 50px 0 10px 0;
      color: ${({ theme }) => theme.lightGray};
    }
  }

  .update-plan {
    width: 100%;
  }

  .description {
    background-color: #f4f5fa;
    padding: 1rem 10rem 1rem 1rem;
    p {
      margin-top: 0.5rem;
    }
  }

  .--input-wrapper-invite {
    align-items: flex-start;
    background-color: ${({ theme }) => theme.primary_blue};
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.lightGray};
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    color: ${({ theme }) => theme.white};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2rem;
    padding: 2rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    padding: 1rem;
    .update-plan {
      display: flex;
      flex-direction: column;
    }
    .description {
      padding: 1rem;
    }
    .--input-wrapper-invite {
      padding: 1rem;
      width: 100%;
    }
  }
`;

export const HeaderElement = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 0px 0px 50% 50% / 0px 0px 15px 15px;
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: center;
  padding: 40px 0;
  width: 100%;

  .--title {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    align-self: center;
    color: ${({ theme }) => theme.primaryAlt};
  }

  .--text {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    align-self: center;
    padding-top: 10px;
    color: ${({ theme }) => theme.primaryAlt};
  }
`;

export const Input = styled(FixedTextInput)``;
export const SelectButton = styled(SlimButton)``;
export const RegisterButton = styled(BorderButton)``;

export const ModalDialog = styled(Modal)`
  display: flex;
  padding: 10px;
  max-width: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.primaryAlt};
  border-radius: 15px;
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
`;

export const HeaderTitle = styled.h1`
  font-size: 18px;
  line-height: 150%;
  color: ${({ theme }) => theme.darkGray};
`;

export const HeaderSubTitle = styled.h2`
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => theme.lightGray};
`;

export const HeaderBody = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.ExtralightGray};
  border-radius: 10px;
`;

export const ModalItemList = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
`;

export const ModalTextItem = styled.h1`
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => theme.darkGray};
`;

export const ItemListContainer = styled.button`
  flex: 1;
  margin: 5px 0;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  //ant-button
  background-color: transparent;
  border: none;
`;

export const ItemCheckBox = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme, checked }) =>
    checked ? theme.primary : 'transparent'};
  border: 1px solid ${({ theme }) => theme.lightGray};
`;

export const ItemContent = styled.div`
  flex: 1;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const ItemName = styled(BigFont)`
  font-weight: 700;
  font-family: 'Poppins';
  color: ${({ theme, full }) => (full ? 'white' : theme.secondary)};
`;

export const ItemPrice = styled(SmallFont)`
  font-weight: 500;
  font-family: 'Poppins';
  color: ${({ theme, full }) => (full ? 'white' : theme.secondary)};
`;

export const ItemLink = styled.button`
  text-decoration: underline;
  border: none;
  width: 100%;
  background-color: transparent;
  font-size: 14px;
  line-height: 150%;
  font-weight: 300;
  padding: 0 5px;
  color: ${({ theme, full }) => (full ? theme.primaryAlt : theme.secondary)};
`;

export const AbsoluteCircle = styled.input`
  width: 90px;
  height: 90px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.offWhite};
`;

export const AbsoluteImage = styled.img`
  width: 90px;
  height: 90px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.offWhite};
`;

export const CameraIcon = styled(Camera)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.lightGray};
`;

// avalilability

export const DayContainer = styled.button`
  width: 400px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid ${({ theme }) => theme.color10};
  .--container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.primaryAlt};

    &-time {
      width: 65%;
      display: flex;
      align-items: center;
      flex-direction: row;
      align-self: flex-start;
      justify-content: flex-start;
      background-color: ${({ theme }) => theme.primaryAlt};
    }
    &-add {
      width: 50%;
      display: flex;
      padding-top: 10px;
      align-items: center;
      flex-direction: row;
      align-self: flex-start;
      justify-content: flex-end;
      background-color: ${({ theme }) => theme.primaryAlt};
    }
    &-button {
      padding-left: 10%;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      background-color: ${({ theme }) => theme.primaryAlt};
    }
    &-delete {
      padding: 0 5%;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      background-color: ${({ theme }) => theme.primaryAlt};
    }
  }
  .--wrapper {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    background-color: ${({ theme }) => theme.primaryAlt};

    &-checkbox {
      width: 40px;
      height: 20px;
    }
    &-bold {
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
      color: ${({ theme }) => theme.secondary};
    }

    &-light {
      font-size: 14px;
      line-height: 21px;
      color: ${({ theme }) => theme.lightGray};
    }
  }
`;

export const CheckBox = styled(CheckBoxInput)``;

export const CopyIcon = styled(Copy)`
  width: 18px;
  color: ${({ theme }) => theme.primary};
`;

export const TrashIcon = styled(Trash2)`
  width: 20px;
  color: ${({ theme }) => theme.primary};
`;
export const PlusIcon = styled(Plus)`
  width: 20px;
  color: ${({ theme }) => theme.primary};
`;

export const RowItemDay = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const LabelContainer = styled.section`
  position: relative;
  display: flex;
  width: 45%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`;

export const RowLine = styled.section`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;

  border-bottom: 1px solid ${({ theme }) => theme.color11};
`;

export const ContainerLabel = styled(SmallFont)`
  align-self: flex-start;
  color: ${({ theme }) => theme.darkGray};
`;

export const SubmitButtom = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.primary_blue};
  border-radius: 0.7rem;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  width: 100%;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const DayContent = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const DayField = styled(Field)``;

export const DayFragment = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color10};
`;

export const Checkabel = styled.label`
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => theme.secondary};
`;

export const CheckValue = styled.label`
  font-size: 14px;
  line-height: 150%;
  padding-left: 10px;
  color: ${({ theme, checked }) => (checked ? theme.primary : theme.darkGray)};
`;

export const CheckBoxRow = styled.section`
  display: flex;
  width: 25%;
  align-items: center;
  justify-content: flex-start;
`;

export const CheckBoxWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme, full, activedColor, color }) =>
    full ? activedColor || theme.secondary : color || theme.primary};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const ButtonWrapper = styled.button`
  align-items: center;
  background-color: ${({ theme, full, activedColor, color }) =>
    full ? activedColor || theme.secondary : color || theme.primary};
  border: none;
  border-bottom: 1px solid
    ${({ theme, full }) => (full ? theme.white : theme.darkGray)};
  cursor: pointer;
  display: flex;
  font-weight: 400;
  justify-content: center;
  width: 100%;
`;

export const ButtonInvite = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.black};
  border-radius: 2.5rem;
  border: none;
  color: ${({ theme }) => theme.white};
  display: flex;
  font-weight: 600;
  height: 2rem;
  justify-content: center;
  margin-top: 1rem;
  padding: 0.625rem 1rem;
  width: 15rem;
`;

export const LinkWrapper = styled.a`
  align-items: center;
  color: ${({ theme, full }) => (full ? 'white' : theme.secondary)};
  display: flex;
  font-family: 'Poppins';
  font-size: 0.9rem;
  font-weight: 700;
  justify-content: center;
  padding: 10px;
`;

export const Button = styled.button`
  background-color: ${({ theme, secondary }) =>
    secondary === 'true' ? theme.white : theme.primary};
  border-radius: 2rem;
  border: 1px solid
    ${({ theme, secondary }) =>
      secondary === 'true' ? theme.colorRed : theme.primary};
  color: ${({ theme, secondary }) =>
    secondary === 'true' ? theme.colorRed : theme.white};
  font-weight: 700;
  height: 2.5rem;
  margin: 0rem 1rem;
  padding: 0rem 2rem;
`;

export const BoxWrapper = styled(ModalBoxWrapper)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: flex-start;
  padding: 1rem;
  width: 500px;

  .--content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }

  .--buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2rem;
    width: 100%;
  }
`;

export const ImgContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const LoginWrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem 0;
  width: 60%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 90%;
  }
`;

export const InputWrapper = styled.div`
  align-items: flex-start;
  animation: ${moveBottomToTop} 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const SendButton = styled.button`
  background-color: ${({ theme }) => theme.secondary};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 600;
  height: 2.5rem;
  transition: 0.4s;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.LightWhite};
    border: 1px solid ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.secondary};
    font-size: 1.2rem;
    font-weight: 900;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const ClientForm = styled(ScrollCardStyle)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  overflow-y: scroll;
  width: 100%;
  z-index: 0;
`;

export const ClinicForm = styled(ClientForm)``;
