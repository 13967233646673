/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Modal, Typography } from "@material-ui/core";
import { Tooltip } from "@mui/material";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import * as Feather from "react-feather";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getCancellationStatus } from "../../../../components/EventElements";
import LoaderSpinner from "../../../../components/LoaderSpinner";
import useAuth from "../../../../hooks/auth";
import useEvent from "../../../../hooks/event";
import EmptyEvent from "../../../../images/empty-dashboard.png";
import api from "../../../../services/api";
import { PtBr } from "../../../../services/pt_br";
import { CloseIcon, IconAreaModal } from "../../../../styles/globalStyles";
import camalize from "../../../../utils/camalize";
import convertCurrencyPtBr from "../../../../utils/convertCurrencyPtBr";
import {
  BoxWrapper,
  CalendarContainer,
  ContainerImg,
  ContainerSchedule,
  IconContainer,
  IconsContainer,
  StyledList,
  TextSchedule,
  WeekItemList,
  WeekList,
  WeekText,
  WrapperList,
} from "../styles";

function WeekItem({ ...props }) {
  const { date } = props;
  const { user } = useAuth();
  const { deleteEvent } = useEvent();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [daySchedules, setDaySchedules] = useState([]);
  const [scheduleInfo, setScheduleInfo] = useState(null);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const formatedDate = moment(date).format("YYYY-MM-DD");
  const currentDate = moment().format("YYYY-MM-DD");

  const getTimesSchedules = time => moment(time, "HH:mm:ss").format("HH:mm");
  const addMinutes = (time, minutes) =>
    moment(time, "HH:mm:ss").add(minutes, "minutes").format("HH:mm");

  const handleStatus = status => {
    if (
      [
        "refunded",
        "waiting_payment",
        "pending_refund",
        "refused",
        "pending",
        null,
      ].includes(status)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleModal = modalNumber => {
    if (modalNumber === 1) {
      if (
        getCancellationStatus(
          selectedEvent.attributes.startDate,
          selectedEvent.attributes.startTime,
        ) === "uncancellable_less_than_24h"
      ) {
        setModal(false);
        setModal2(true);
        return;
      } else {
        handleDelete(selectedEvent);
      }
    } else if (modalNumber === 2) {
      handleDelete(selectedEvent);
    }
  };

  const handleDelete = async event => {
    setLoading(true);
    setModal(false);
    setModal2(false);
    if (
      getCancellationStatus(
        event.attributes.startDate,
        event.attributes.startTime,
      ) === "cancellable" ||
      "uncancellable_less_than_24h"
    ) {
      try {
        const response = await deleteEvent(event.id);
        if (response.status === 200) {
          toast.success(response.message);
        } else {
        }
      } catch (error) {
        toast.error("Erro ao cancelar o agendamento");
      }
    } else {
      toast.warning("Esse agendamento não pode ser cancelado!");
    }
    setLoading(false);
  };

  useEffect(() => {
    const controller = new AbortController();
    const getDaySchedules = async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/clients/schedules", {
          params: {
            start_date: moment(date).format("YYYY-MM-DD"),
          },
          signal: controller.signal,
        });
        setDaySchedules(data.data);
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error("Erro ao carregar os agendamentos");
      } finally {
        setLoading(false);
      }
    };
    getDaySchedules();

    return () => {
      setDaySchedules([]);
      controller.abort();
    };
  }, [date]);

  return (
    <WeekItemList
      active={"true"}
      current={formatedDate === currentDate ? "true" : "false"}
    >
      <WeekItemList>
        <WeekText
          active={"true"}
          current={formatedDate === currentDate ? "true" : "false"}
        >
          {date.getDate()} de {PtBr.months_name[date.getMonth()].toLowerCase()}
        </WeekText>
        <WeekText
          active={"true"}
          current={formatedDate === currentDate ? "true" : "false"}
        >
          {PtBr.days_name[date.getDay()]}
        </WeekText>
      </WeekItemList>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <Fragment>
          {daySchedules?.length > 0 ? (
            <WeekList>
              {daySchedules.map(item => {
                const { attributes } = item;
                return (
                  <ContainerSchedule key={item.id}>
                    <TextSchedule primary={true}>
                      {getTimesSchedules(attributes.startTime)} -{" "}
                      {addMinutes(attributes.startTime, attributes.duration)}
                      <Tooltip title="Informações do agendamento.">
                        <Feather.User
                          color="#999EBC"
                          style={{
                            backgroundColor: "#fff",
                            border: "2px solid #fff",
                            borderRadius: "50%",
                            cursor: "pointer",
                            padding: "1px",
                          }}
                          size="2.2rem"
                          onClick={() => {
                            setModalInfo(true);
                            setScheduleInfo(attributes);
                          }}
                        />
                      </Tooltip>
                    </TextSchedule>
                    <TextSchedule>
                      {attributes?.professional?.firstName}
                    </TextSchedule>
                    <IconsContainer>
                      <Tooltip title="Cancelar agendamento.">
                        <IconContainer
                          onClick={() => {
                            const status = getCancellationStatus(
                              attributes.startDate,
                              attributes.startTime,
                            );
                            if (status === "uncancellable_already_done") {
                              toast.warning(
                                "Ops.. este agendamento já foi realizado",
                              );
                              return;
                            } else if (
                              status === "cancellable" ||
                              "uncancellable_less_than_24h"
                            ) {
                              setModal(true);
                              setSelectedEvent(item);
                              return;
                            }
                          }}
                        >
                          <Feather.Trash2 color="#999EBC" size="24px" />
                        </IconContainer>
                      </Tooltip>
                      {attributes?.appointmentType === "in_person" ? (
                        <Tooltip title="Consulta presencial.">
                          <IconContainer
                            onClick={() => {
                              setModalInfo(true);
                              setScheduleInfo(attributes);
                            }}
                          >
                            <Feather.MapPin color="#999EBC" size="24px" />
                          </IconContainer>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Entrar na consulta.">
                          <IconContainer
                            onClick={() => {
                              if (
                                getCancellationStatus(
                                  attributes.startDate,
                                  attributes.startTime,
                                ) === "uncancellable_already_done" ||
                                getCancellationStatus(
                                  attributes.startDate,
                                  attributes.startTime,
                                ) === "time_exceeded"
                              ) {
                                toast.warning(
                                  "Ops.. este agendamento já foi realizado",
                                );
                                return;
                              } else if (
                                handleStatus(attributes.paymentStatus)
                              ) {
                                toast.error(
                                  "Ops.. você não pode entrar em uma consulta que não foi paga",
                                );
                                return;
                              } else if (attributes.paymentStatus === "paid") {
                                if (attributes.ownMeetingLink) {
                                  window.open(
                                    attributes.ownMeetingLink,
                                    "_blank",
                                  );
                                } else {
                                  history.push({
                                    pathname: `/${user?.type}/meeting`,
                                    state: {
                                      schedule: { id: item.id, ...attributes },
                                    },
                                  });
                                }
                              }
                            }}
                          >
                            <Feather.Video color="#999EBC" size="24px" />
                          </IconContainer>
                        </Tooltip>
                      )}

                      {handleStatus(attributes.paymentStatus) && (
                        <Tooltip title="Pagar agendamento.">
                          <IconContainer
                            onClick={() =>
                              history.push(`/client/payment/${item.id}`)
                            }
                          >
                            <Feather.DollarSign color="red" size="24px" />
                          </IconContainer>
                        </Tooltip>
                      )}
                    </IconsContainer>
                  </ContainerSchedule>
                );
              })}
            </WeekList>
          ) : (
            <WeekList>
              <ContainerImg>
                <img
                  src={EmptyEvent}
                  alt="empty"
                  style={{ width: "70%", alignSelf: "center" }}
                />
                <p
                  style={{
                    color: "#999EBC",
                    fontSize: "0.8rem",
                    marginTop: "0.7rem",
                    textAlign: "center",
                  }}
                >
                  Nenhum agendamento para este dia
                </p>
              </ContainerImg>
            </WeekList>
          )}
        </Fragment>
      )}

      {modal && (
        <Modal open onClose={() => setModal(false)}>
          <BoxWrapper>
            <Typography variant="h6" sx={{ mt: 2 }}>
              {" "}
              O agendamento selecionado será cancelado. Essa ação é
              irreversível, deseja continuar?{" "}
            </Typography>

            <Grid
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
            >
              <Button
                sx={{ m: 1 }}
                onClick={() => {
                  setModal(false);
                }}
                size="small"
                variant="contained"
              >
                Não
              </Button>
              <Button
                sx={{ m: 1 }}
                onClick={() => handleModal(1)}
                size="small"
                variant="contained"
              >
                Sim
              </Button>
            </Grid>
          </BoxWrapper>
        </Modal>
      )}

      {modal2 && (
        <Modal open onClose={() => setModal2(false)}>
          <BoxWrapper>
            <Typography variant="h6" sx={{ mt: 2 }}>
              {" "}
              Agendamentos cancelados dentro de 24 horas não serão estornados,
              deseja continuar?{" "}
            </Typography>

            <Grid
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
            >
              <Button
                sx={{ m: 1 }}
                onClick={() => {
                  setModal2(false);
                }}
                size="small"
                variant="contained"
              >
                Não
              </Button>
              <Button
                sx={{ m: 1 }}
                onClick={() => handleModal(2)}
                size="small"
                variant="contained"
              >
                Sim
              </Button>
            </Grid>
          </BoxWrapper>
        </Modal>
      )}

      {modalInfo && (
        <Modal open>
          <BoxWrapper>
            <IconAreaModal>
              <CloseIcon onClick={() => setModalInfo(false)} />
            </IconAreaModal>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", fontWeight: "700" }}
            >
              Detalhes do agendamento
            </Typography>
            <WrapperList>
              <StyledList primary={"true"}>
                <li>
                  <span className="--title">Profissional:</span>{" "}
                  <span className="--content">
                    {scheduleInfo?.professional?.firstName}{" "}
                    {scheduleInfo?.professional?.lastName}
                  </span>
                </li>
                <li>
                  <span className="--title">Dia:</span>{" "}
                  <span className="--content">
                    {moment(scheduleInfo?.startDate, "YYYY-MM-DD").format(
                      "DD/MM/YYYY",
                    )}
                  </span>
                </li>
                <li>
                  <span className="--title">Hora:</span>{" "}
                  <span className="--content">
                    {moment(scheduleInfo?.startTime, "HH:mm:ss").format(
                      "HH:mm",
                    )}
                  </span>
                </li>
                <li>
                  <span className="--title">Valor:</span>{" "}
                  <span className="--content">
                    {convertCurrencyPtBr(scheduleInfo?.paymentAmount)}
                  </span>
                </li>
                <li>
                  <span className="--title">Status:</span>{" "}
                  <span>
                    {scheduleInfo?.paymentStatus === "paid"
                      ? "Pago"
                      : "Pendente de pagamento"}
                  </span>
                </li>
                <li>
                  <span className="--title">Tipo:</span>{" "}
                  <span className="--content">
                    {scheduleInfo?.appointmentType === "in_person"
                      ? "Presencial"
                      : "Online"}
                  </span>
                </li>
                {scheduleInfo?.paymentStatus === "paid" && (
                  <li>
                    <span className="--title">Endereço:</span>{" "}
                    <span className="--content">
                      {scheduleInfo?.appointmentType === "online" ? (
                        <a
                          href={
                            scheduleInfo?.ownMeetingLink ||
                            scheduleInfo?.startUrl
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link da reunião
                        </a>
                      ) : (
                        `${camalize(
                          scheduleInfo?.professional?.address?.street,
                        )},
                        Número ${
                          scheduleInfo?.professional?.address?.street_number ||
                          "S/N"
                        },
                        Bairro ${
                          camalize(
                            scheduleInfo?.professional?.address?.neighborhood,
                          ) || ""
                        },
                        Cidade ${
                          camalize(scheduleInfo?.professional?.address?.city) ||
                          ""
                        },
                        Estado ${
                          camalize(
                            scheduleInfo?.professional?.address?.state,
                          ) || ""
                        }`
                      )}
                    </span>
                  </li>
                )}
              </StyledList>
            </WrapperList>
          </BoxWrapper>
        </Modal>
      )}
    </WeekItemList>
  );
}

export function CalendarComponent({ ...props }) {
  const { date, dayColumn } = props;
  const [days, setDays] = useState([]);

  useEffect(() => {
    const getDates = () => {
      const numberWeek = [];
      for (let i = 0; i < dayColumn; i += 1) {
        numberWeek.push(moment(date).add(i, "days").toDate());
      }
      return numberWeek;
    };
    setDays(getDates());
  }, [dayColumn, date]);

  return (
    <CalendarContainer>
      {days.map(item => (
        <WeekItem key={item} date={item} />
      ))}
    </CalendarContainer>
  );
}
