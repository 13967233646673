import {
  AccountBalance,
  Assignment,
  AttachMoney,
  CalendarMonth,
  EventNote,
  Group,
  Home,
  Logout,
  Message,
  Person,
  ReceiptLong,
  Settings,
  Source,
  ViewInAr,
  RequestQuote,
} from "@mui/icons-material";
import React from "react";

export default function sideBarDatas(user) {
  return [
    {
      id: 1,
      title: "Painel",
      path: `/${user.type}/dashboard`,
      className: "nav-text",
      icon: <Home />,
      active: "false",
    },
    {
      id: 2,
      title: "Clientes",
      path: `/${user.type}/patients`,
      className: "nav-text",
      icon: <Group />,
      active: "false",
    },
    {
      id: 3,
      title: "Buscar profissionais",
      path: `/${user.type}/professionals`,
      className: "nav-text",
      icon: <Group />,
      active: "false",
    },
    {
      id: 4,
      title: "Profissionais",
      path: `/${user.type}/professionals`,
      className: "nav-text",
      icon: <Group />,
      active: "false",
    },
    {
      id: 5,
      title: "Agenda",
      path: `/${user.type}/calendar`,
      className: "nav-text",
      icon: <CalendarMonth />,
      active: "false",
    },
    {
      id: 6,
      title: "Perfil",
      path: `/${user.type}/profile`,
      className: "nav-text",
      icon: <ReceiptLong />,
      active: "false",
    },
    {
      id: 7,
      title: "Relatório de Consultas",
      path: `/${user.type}/schedules_report`,
      className: "nav-text",
      icon: <EventNote />,
      active: "false",
    },
    {
      id: 8,
      title: "Conteúdos",
      path: `/${user.type}/contents`,
      className: "nav-text",
      icon: <Source />,
      active: "false",
    },
    {
      id: 9,
      title: "Mensagens",
      path: `/${user.type}/messages`,
      className: "nav-text",
      icon: <Message />,
      active: "false",
    },
    {
      id: 10,
      title: user.type === "clinic" ? "Financeiro" : "Carteira",
      path: `/${user.type}/finances`,
      className: "nav-text",
      icon: user.type === "clinic" ? <AttachMoney /> : <AccountBalance />,
      active: "false",
    },
    {
      id: 11,
      title: "Extrato de Pagamentos",
      path: `/${user.type}/statement`,
      className: "nav-text",
      icon: <RequestQuote />,
      active: "false",
    },
    {
      id: 12,
      title: "Planos de Assinatura",
      path: `/${user.type}/subscription`,
      className: "nav-text",
      icon: user.type === "clinic" ? <ViewInAr /> : <Assignment />,
      active: "false",
    },
    {
      id: 13,
      title: "Configurações",
      path: `/${user.type}/config`,
      className: "nav-text",
      icon: <Settings />,
      active: "false",
    },
    {
      id: 14,
      title: "Perfil Público",
      path: `/${user.type}/profile`,
      className: "nav-text",
      icon: <Person />,
      active: "false",
    },
    // {
    //   title: 'Ajuda',
    //   href: 'https://wa.me/5571999960614',
    //   className: 'nav-text',
    //   icon: <WhatsApp />,
    //   active: 'false',
    // },
    {
      id: 15,
      title: "Sair",
      path: `/${
        user.type === "clinic"
          ? "clinica"
          : user.type === "client"
          ? "cliente"
          : "profissional"
      }/conectar-se`,
      className: "nav-text",
      icon: <Logout />,
      active: "true",
    },
  ];
}
