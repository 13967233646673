import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

export const TextAlert = styled.h1`
  font-size: 1.2rem;
  font-weight: 700;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
  width: 100%;
`;

export const IconArea = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 1rem;
`;

export const Icon = styled.img`
  height: 2rem;
  width: 2rem;
`;
